import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

const headers: any = new HttpHeaders({
  'Content-Type': 'application/json'
  //  Cookie : 'IDSESSIONMDC=0b5bce83-f530-4bcd-94bd-61b76192eb6d;domain=10.98.4.161:8080'
});

@Injectable({
  providedIn: 'root'
})
export class BrokerService {

  dataLinea: any;
  nameCompanyAccept: any;
  account: any;

  constructor(public http: HttpClient, private cookieService: CookieService, public router: Router) { }

  contenido_interface() {
    return this.http.get('assets/data/content.json');
  }

  validar_sesion(body: any) {
    return this.http.post(environment.APIEndpoint_authn + '/authn/validateSession ', body, { headers });
  }

  cerrar_sesion(body: any) {
    // const sessionId = this.cookieService.get('IDSESSIONMDC');
    return this.http.post(environment.APIEndpoint_authn + '/authn/signOff', body, { headers });
  }

  /**
   * Metodo que llama al servicio de /pontealdia/v1/debt enviando un objeto que tiene el sessionID como parametro
   * @returns lista de las deudas del usuario
   */
  consultarDeudas() {
    const body = JSON.stringify({
      sessionID: this.cookieService.get('IDSESSIONMDC')
    });
    return this.http.post(environment.APIEndpoint_debts + environment.consultaDeudas, body, { headers });
  }

  companyParams(body: any) {
    const sessionId = this.cookieService.get('IDSESSIONMDC');
    return this.http.post(environment.APIEndpoint_company + '/companymanager/entityParameters', body, { headers });
  }

  negociarDeudas(body: any) {
    return this.http.post(environment.APIEndpoint_ngttion + environment.negociarDeudas, body, { headers });
  }

  negotiateObligation(body: any) {
    return this.http.post(environment.APIEndpoint_debts + environment.negociarObligaciones, body, { headers });
  }

  findObligations() {
    const body = JSON.stringify({
      sessionID: this.cookieService.get('IDSESSIONMDC')
    });
    return this.http.post(environment.APIEndpoint_debts + environment.consultarObligaciones, body, { headers });
  }

  guardarPropuestaObligacion(body: any) {
    return this.http.post(environment.APIEndpoint_debts + environment.guardarPropuestaObligacion, body, { headers });
  }

  descargarPDF(body: any) {
    return this.http.post(environment.APIEndpoint_company + environment.descargarPDF, body, { headers });
  }

  guardarPropuesta(body: any) {
    return this.http.post(environment.APIEndpoint_debts + environment.guardarPropuesta, body, { headers });
  }

  pagarLinea(body: any) {
    return this.http.post(environment.APIEndpoint_debts + environment.pagarLinea, body, { headers });
  }

  otraPropuesta(body: any) {
    return this.http.post(environment.APIEndpoint_ngttion + environment.otraPropuesta, body, { headers });
  }
  customerService(Data: any) {
    const token = '7YZFRCneLTzRm5vZjsA6DhqNyDgrztbJtRA8SBKV';
    const storeId = 'QXexb3a9';
    const eventType = 'customers/update';// See "Event types" 

    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Event-Type': eventType,
      'X-Token': token,
      'Cache-Control': 'no-cache'
    });

    return this.http.post(environment.webhooks + storeId, Data, { headers });
  }

  cartService(Data: any) {
    const token = '7YZFRCneLTzRm5vZjsA6DhqNyDgrztbJtRA8SBKV';
    const storeId = 'QXexb3a9';
    const eventType = 'carts/create';// See "Event types" 

    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Event-Type': eventType,
      'X-Token': token,
      'Cache-Control': 'no-cache'
    });

    return this.http.post(environment.webhooks + storeId, Data, { headers });
  }

  orderService(Data: any) {
    const token = '7YZFRCneLTzRm5vZjsA6DhqNyDgrztbJtRA8SBKV';
    const storeId = 'QXexb3a9';
    const eventType = 'orders/update';// See "Event types" 

    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Event-Type': eventType,
      'X-Token': token,
      'Cache-Control': 'no-cache'
    });

    return this.http.post(environment.webhooks + storeId, Data, { headers });
  }

  getCustomerCampaign(utm: any) {
    const body = JSON.stringify({
      sessionId: this.cookieService.get('IDSESSIONMDC'),
      campaignId: utm
    });
    return this.http.post(environment.APIEndpoint_bank + environment.Bank.customercampaigninfo, body, { headers });
  }

  applyCampaign(data: any) {
    const body = JSON.stringify(data);
    return this.http.post(environment.APIEndpoint_bank + environment.Bank.applyCampaign, body, { headers });
  }

  getAllies(){
      return this.http.get(environment.enlaces_cloudFront.url + environment.enlaces_cloudFront.pntdPath + environment.cloudFront.allies);
  }
  // getSliders(){
  //   return this.http.get('assets/data/sliders.json');
  // }

  /**
   * Metodo que llama el servicio /pontealdia/v1/process/preload/info
   * @param data josn que tiene el sessionID y idConsumerdebts
   * @returns retorna la url de escala
   */
  getUrlEscala(data: object){
    // const body = JSON.stringify(data);
    return this.http.post(environment.APIEndpoint_debts + environment.urlEscala, data, { headers });
  }

}