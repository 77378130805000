<div class="container print-main">
	<div class="gral escala">
		<div class="row justify-content-center">
			<div class="col-12 col-md-12">
				<div class="prevPage" (click)='backAdmin()'><i class="fa fa-chevron-left"></i><span>Volver al
						administrador</span></div>
				<h2 class="title">Este es tu acuerdo con {{select.state?.agreement.nameBank}}</h2>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-7">
				<div class="box-pagos">
					<p class="title d-none d-md-block">Programación de pagos</p>
					<hr class="sep">
					<div *ngFor="let item2 of select.state?.agreement.paymentAgreements; let i = index;">
						<div class="row lista">
							<div class="col-1 col-md-1 first itemLista text-center">
								<p class="pRegular">No.</p>
								<p class="pBold">{{i+1}}</p>
							</div>
							<div class="col-5 col-md-3 second itemLista">
								<p class="pRegular">Fecha</p>
								<p class="pBold">{{item2.agreementDate | date: 'dd MMMM yyyy' }}</p>
							</div>
							<div class="col-3 col-md-2 itemLista">
								<p class="pBold">Total</p>
								<!-- <p class="pColor">Ahorro</p> -->
							</div>
							<div class="col-3 col-md-2 last itemLista">
								<p class="pBold">${{item2.agreementAmount | currency:'COP':'':'1.0-0'}}</p>
								<!-- <p class="pColor">${{item2.agreementAmount | currency:'COP':'':'1.0-0'}}</p> -->
							</div>
							<div class="col-12 col-md-4 box-btn">
								<button class="btnPagar" (click)="pagarLinea(select.state?.parameters[4].value)">Pagar
									en linea <i class="fa fa-chevron-right" aria-hidden="true"></i></button>
							</div>
						</div>
						<hr class="sub-sep">
					</div>
				</div>
				<div class="box-info">
					<p class="parrafo">Ante cualquier duda, puedes <span>ponerte en contacto</span> mediante los canales
						de atención de</p>
					<a class="pCon" href="tel: {{select.state?.parameters[0].value}}"><i class="fa fa-phone"
							aria-hidden="true"></i> {{select.state?.parameters[0].value}}</a>
					<a class="pCon" href="mailto: {{select.state?.parameters[3].value}}"><i class="fa fa-envelope-o"
							aria-hidden="true"></i> {{select.state?.parameters[3].value}}</a>
					<a class="pCon" href="http://{{select.state?.parameters[2].value}}">
						{{select.state?.parameters[2].value}}</a>
				</div>
			</div>
			<div class="col-12 col-md-5">
				<div class="resumen">
					<p class="titleR">Resumen del acuerdo de pago</p>
					<hr class="sep">
					<div class="pResumen">
						<p><span>Acreedor original</span></p>
						<p>{{select.state?.agreement.nameBank}}</p>
					</div>
					<div class="pResumen">
						<p><span>No. de la obligación</span></p>
						<p>**** **** {{ broker.account }}</p>
					</div>
					<div class="pResumen">
						<p><span>Tipo de producto</span></p>
						<p>{{select.state?.agreement.nameProduct}}</p>
					</div>
					<div class="pResumen">
						<p><span>Nombre</span></p>
						<p>{{sesion.user?.nombre}} {{sesion.user?.apellido}}</p>
					</div>
					<div class="pResumen">
						<p><span>Saldo inicial de deuda</span></p>
						<p>${{select.state?.agreement.initialBalance | currency:'COP':'':'1.0-0'}}</p>
					</div>
					<div class="pResumen">
						<p><span>Plazo</span></p>
						<p>{{select.state?.agreement.totalPayments}}</p>
					</div>
					<div class="pResumen">
						<p><span>Saldo final de deuda</span></p>
						<p>${{select.state?.agreement.negotiatedValue | currency:'COP':'':'1.0-0'}}</p>
					</div>
					<!-- <button *ngIf="!descargando" class="btn-download-pdf" id="descargarDesktopPDF2{{select.state?.agreement.nameBank}}" (click)='descargarPDF(select.de)'>							
						Descargar pdf
						<i class="fa fa-download"></i>
					</button>
					<div class="spinGral" *ngIf="descargando">
						<div class="spinPurple" role="status">
							<img src="assets/images/spinner.gif" class="spinner-gif" alt="cargando..."/>
						</div>
						<div class="textSpin">Descargando pdf...</div>
					</div>
					<div *ngIf="errorDescarga" class="errorService">Error en la descarga del pdf</div> -->
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<div class="box-next-steps">
					<h3>Tus próximos pasos...</h3>
					<div class="details-steps" [innerHTML]="select.state?.parameters[5].value">
					</div>
				</div>
			</div>
		</div>

		<div class="d-none d-md-block">
			<p class="parrafo">Ten en cuenta que, si no pagas a tiempo tu(s) cuota(s), esta negociación puede ser
				anulada. <br>Una vez que <span>{{select.state?.agreement.nameBank}}</span> haya recibido y liquidado el pago de acuerdo con las
				condiciones de
				esta negociación, se actualizará tu deuda para reflejar que el pago ha sido exitoso, y si <span>{{select.state?.agreement.nameBank}}</span>
				reporta la información de manera eficaz a Datacrédito Experian, la actualización se verá reflejada en tu
				historial de crédito.
			</p>
		</div>
	</div>
	<!-- <a id="descargaPDF" [href]="base64" download="acuerdo_de_pago.pdf" style="display: none;"></a> -->
</div>
<p class="pGray d-block d-md-none">Ten en cuenta que, si no pagas a tiempo tu(s) cuota(s), esta negociación puede ser
	anulada. <br>
	Una vez que <span>{{select.state?.agreement.nameBank}}</span> haya recibido y liquidado el pago de acuerdo con las condiciones de
	esta negociación, se actualizará tu deuda para reflejar que el pago ha sido exitoso, y si <span>{{select.state?.agreement.nameBank}}</span>
	reporta la información de manera eficaz a Datacrédito Experian, la actualización se verá reflejada en tu
	historial de crédito.
</p>