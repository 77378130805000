import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BrokerService } from './broker.service';
import { SesionService } from './sesion.service';
// import { NgbCalendar, NgbDatepickerConfig, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { OfferSelectedService } from './offer-selected.service';
// import { AnonymousSubject } from 'rxjs/internal/Subject';
//import { Console } from 'console';
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class DealService {
    debtItem: any;
    debBalance: any;
    debCompany: any;
    selectDays: any;
    selectMonth: any;
    agreements: any = null;
    type: any = 0;
    daysConvert: any;
    totalDebt: any;
    today = new Date();
    spinner = false;
    daysTime: any;

    //Moras tempranas
    quotaNumber: any;
    daysInQuota: any;
    notFoundOffer: boolean = false;
    valueCoute: any;

    //Integrations
    totalMonth: boolean = false;
    integration: number = 0;

    idProduct: any;
    public loading: boolean = false;
    constructor(
		public broker: BrokerService,
		public sesion: SesionService,
		public router: Router,
		// public calendar: NgbCalendar,
		public select: OfferSelectedService,
		// public config: NgbDatepickerConfig
		) {
    }
	close() {
		this.debtItem = '';
		this.debBalance = '';
		this.debCompany = '';
		this.type = '';
		$('#modal-deal').modal('toggle');
	}

  	open(item: any) {
		this.debtItem = item;
		this.debBalance = item.balance;
		this.debCompany = item.nameCompany;
		this.totalDebt = item.totalDebt;
		$('#modal-deal').modal('show');	
  	}
	openDebtDirect(item: any, i:any) {
		this.debtItem = item;
		this.debBalance = item.balance;
		this.debCompany = item.nameCompany;
		this.totalDebt = item.totalDebt;
		//this.propuestaMorasDirect();
		if(this.type == 3) {
			this.propuestaMorasDirect(i);
		} else {
			this.propuestaDirect(i);
		}
  	}

  	public validateDays(days: number, totalMonth: boolean) {
		var str = 0;
		var arrayMonth = [];
		let value: any;
		let tem: any;
    	if (days > 0) {
      		var values = [[' mes', 30], [' día', 1]];
      		for (var i = 0; i < values.length; i++) {
				value = values[i][1];
				var amount = Math.floor(days / value);
        		if (amount >= 1) {
          			tem = values[i][0];
          			if (tem == " mes") {
						str += amount;
						days -= amount * value;
					}
        		}
      		}
      		if (str == 1) {
        		arrayMonth.push(str);
     		} else {
        		var count = 1;
				for (var j = 1; j <= str; j++) {
					arrayMonth.push(count++);
				}

				if (totalMonth && arrayMonth.length > 1) {
					var month = arrayMonth.length;
					arrayMonth = [];
					arrayMonth.push(month);
				}
      		}
    	}
    	return arrayMonth;
  	}

  	companyParams(item: any) {
		const data = {
			idProduct: item.idProduct,
			nit: item.nitCompany,
			project: 'Ponte Al Dia',
			type: item.type,
			country: environment.country,
			idSession: this.sesion.sesionCookie
		};
   		this.broker.companyParams(data).subscribe((res: any) => {
			if (res.status_code == 404) {
				this.close();
			}
			this.select.offerBank = res.product;
			this.selectDays = res.product.daysAvailablePayment;
			this.selectMonth = res.product.paymentsQuantity;
			this.quotaNumber = res.product.quotaNumber;
			this.totalMonth = res.project.totalMonth;
			this.daysConvert = this.validateDays(item.delinquencyInDays, this.totalMonth);
			this.daysInQuota = res.product.daysInQuota == null || res.product.daysInQuota == 0 ? 30 : res.product.daysInQuota;
			this.integration = res.project.integration == null ? 0 : res.project.integration;
      		//Abrir el modal
      		this.open(item);
    	}, error => {
      		this.close();
    	});
  	}
	//Revisar por cambio de version
  	// configDate(days: any) {
	// 	// this.config.minDate = this.calendar.getToday();
	// 	const num = days.length;
	// 	let maxDay = days[num - 1];
	// 	maxDay = maxDay.split('/');
	// 	// this.config.maxDate = { day: parseInt(maxDay[2]), month: parseInt(maxDay[1]), year: parseInt(maxDay[0]) };
	// 	// days that don't belong to current month are not visible
	// 	// this.config.outsideDays = 'hidden';
	// 	// weekends are disabled
	// 	//this.config.markDisabled = (date: NgbDate) => this.calendar.getWeekday(date) >= 6;
		
  	// }

  	dealService(data: any) {
    	this.loading = true;
    	this.broker.negociarDeudas(data).subscribe((res: any) => {
			this.loading = false;
			if (res.errorCode == "0") {
				this.notFoundOffer = false;				
				this.agreements = res.data;				
				$('#modal-deal').modal('toggle');
				setTimeout(() => {
				// Size window
				if($(window).width() >= 768){
						$('.cta-plan-select').addClass('d-flex');
						$('.info-agreement').collapse('show');
					} else {
						$('.info-agreement').collapse('hide');
						$('.cta-plan-select').addClass('d-none');
					}
					// Size window
				}, 500);			
				//this.router.navigate(['/propuesta']);
			} else {
				this.notFoundOffer = true;
			}
    	}, error => {
      		this.loading = false;
    	});
  	}

  	dealServiceDirect(data: any, i:any) {
		this.loading = true;
		this.broker.negociarDeudas(data).subscribe((res: any) => {
				this.loading = false;
				if (res.errorCode == "0") {
					this.notFoundOffer = false;
					this.agreements = res.data;
					$(".spinGral").addClass("d-none");
					this.router.navigate(['/propuesta']);
				} else {
					this.notFoundOffer = true;
					$(".spinGral").removeClass("d-flex");
					//$("#negociarAhora").hide();
					$("#errorDebt"+i).removeClass("d-none");					
				}
		}, error => {
				this.loading = false;
		});
  	}

  	//Parametros para una sola dueda
  	companyParamsDirect(item: any, i:any) {
		const data = {
			idProduct: item.idProduct,
			nit: item.nitCompany,
			project: 'Ponte Al Dia',
			type: item.type,
			country: environment.country,
			idSession: this.sesion.sesionCookie
		};
    		this.broker.companyParams(data).subscribe((res: any) => {
			if (res.status_code == 404) {
				this.close();
			}
			this.select.offerBank = res.product;
			this.selectDays = res.product.daysAvailablePayment;
			this.selectMonth = res.product.paymentsQuantity;
			this.quotaNumber = res.product.quotaNumber;
			this.totalMonth = res.project.totalMonth;
			this.daysTime = res.project.paymentTimeout;
			this.daysConvert = this.validateDays(item.delinquencyInDays, this.totalMonth);
			this.daysInQuota = res.product.daysInQuota == null || res.product.daysInQuota == 0 ? 30 : res.product.daysInQuota;
			this.integration = res.project.integration == null ? 0 : res.project.integration;
			//Enviar a pantalla de propuestas
			this.openDebtDirect(item, i);
		}, error => {
			$(".spinGral").removeClass("d-flex");
			$("#spinnerDebtError"+i).addClass("d-block");
			this.close();
		});
  	}

	propuestaDirect(i:any) {
		const paymentDateForm = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() + 1);
		const body = {
			nit: this.debtItem.nitCompany,
			idSession: this.sesion.sesionCookie,
			idUserProduct: String(this.debtItem.numberAccount),
			productType: this.debtItem.idProduct,
			operationType: this.debtItem.type,
			paymentCapacity: "0",
			numberPayments: "1",
			paymentDate: paymentDateForm,
			nonPredefinedVariables: [
				{
					"prefix": "VD",
					"value": this.debtItem.totalDebt,
					//"value": "1"
				}
			]
		};

		this.dealServiceDirect(body,i);
		}

    	propuestaMorasDirect(i:any) {
		const paymentDateForm = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() + 1);
		if (this.totalMonth == true){
			this.valueCoute = this.daysConvert
		} else {
			this.valueCoute = 1
		}
		const body = {
			nit: this.debtItem.nitCompany,
			idSession: this.sesion.sesionCookie,
			idUserProduct: String(this.debtItem.numberAccount),
			productType: this.debtItem.idProduct,
			operationType: this.debtItem.type,
			paymentCapacity: "0",
			numberPayments: "1",
			daysInQuota: this.daysInQuota,
			paymentDate: paymentDateForm,
			nonPredefinedVariables: [
				{
					"prefix": "VD",
					"value": this.debtItem.balance
				},
				{
					"prefix": "VIC",
					"value": this.debtItem.interestValue
				},
				{
					"prefix": "NMM",
					// "value": "1"
					"value": String(this.valueCoute)					
				}
			]
		};
		this.dealServiceDirect(body,i);
	}
	
}
