<section class="confirm-main">
	<div class="container">
		<div class="gral">
			<div class="row justify-content-center">
				<div class="col-12 order-0 order-md-0">
					<div class="alertCheck check" *ngIf="alertCheck">
						<div class="alertMessage">
							<img src="assets/images/confirmation/ok.svg" class="iconCheck" alt="cehck">
							<div class="txtMessage">
								<h3>¡Felicitaciones!</h3>
								<p>Tu acuerdo de pago ha sido confirmado con la entidad</p>
							</div>							
						</div>
					</div>
					<div class="alertCheck error" *ngIf="alertError" >
						<div class="alertMessage">
							<img src="assets/images/confirmation/bad.svg" class="iconCheck" alt="check">
							<div class="txtMessage">
								<h3>Lo sentimos</h3>
								<p>No fue posible procesar tu acuerdo. Por favor intenta más tarde</p>
							</div>							
						</div>
					</div>
					<a (click)="back()" class="cta-program back" id="volverPropuestas">
						<i class="fa fa-arrow-left" aria-hidden="true"></i>
						Volver a mis propuestas
					</a>
					<div class="steps">
						<p>Paso <span>2</span> de <span>2</span></p>
						<h3>Aceptación y firma</h3>			
					</div>
				</div>
				<div class="col-12 col-md-7 order-2 order-md-1">					
					<h3>Acepta tu acuerdo hoy</h3>
					<p><span>{{debtService.debtSelect.nameCompany}}</span> mantendrá este acuerdo vigente por <span *ngIf="agree.daysTime == 1">{{agree.daysTime}} día</span> <span *ngIf="agree.daysTime > 1">{{agree.daysTime}} días</span> a partir de la fecha de pago.</p>
					<h3>Firma</h3>
					<p>
						Acepto pagar el plan de pago programado por un total de
						<span *ngIf="agree.agreements.isObligation">
							{{ select.offer.newBalance | currency:'COP':'':'1.0-0' }}
						</span>
						<span *ngIf="!agree.agreements.isObligation">
							{{ select.offer.newBalance | currency:'COP':'':'1.0-0' }}
						</span> 
						como se estipula en el <a (click)='open(select.offer.agreement.paymentAgreements, debtService.debtSelect)'>acuerdo de pago.</a>
					</p>
					<div class="checkInput">
						<input *ngIf="sign.check == 1" checked type="checkbox" (click)='acceptCheck()' id="acepto"
							name="acepto" />
						<input *ngIf="sign.check != 1" type="checkbox" (click)='acceptCheck()' id="Noacepto"
							name="acepto" />
						Estoy de acuerdo con la
						<a (click)='openSignature()' id="verRegulacionFirma">Regulación de Firma Electrónica (ABRIR)</a>
					</div>
					<div class="form-group">
						<div class="label" [ngClass]="sign.check != 1 ? 'txt-disabled' : 'txt-check'">Nombre completo</div>
						<input type="text" class="form-control text-left inputDisabled" id="nombre" name="nombre" disabled
						value="{{guard.user?.nombre}} {{guard.user?.apellido}}" [ngClass]="sign.check != 1 ? 'bg-disabled' : 'bg-check'"/>
						<i class="fa fa-check" aria-hidden="true" [ngClass]="sign.check == 1 ? 'check' : 'gray' "></i>
					</div>
					<div class="form-group">
						<div class="label" [ngClass]="sign.check != 1 ? 'txt-disabled' : 'txt-check'">Documento</div>
						<input type="text" class="form-control text-left inputDisabled" id="nombre" name="nombre" disabled
						value="{{guard.user?.documento}}" [ngClass]="sign.check != 1 ? 'bg-disabled' : 'bg-check'"/>
						<i class="fa fa-check" aria-hidden="true" [ngClass]="sign.check != 1 ? 'gray' : 'check'"></i>
					</div>
					<div class="preview">
						<div class="sign">{{guard.user?.nombre}} {{guard.user?.apellido}}</div>
						Previsualización de firma
					</div>
					<div class="finish">
						<button *ngIf="(sign.check == 1)" (click)='confirmOffer(select.offer, debtService.debtSelect)' id="aceptarAcuerdo{{debtService.debtSelect.nameCompany}}">
							Aceptar acuerdo
							<i class="fa fa-angle-right" aria-hidden="true"></i>
						</button>
						<button *ngIf="(sign.check != 1)" disabled (click)='confirmOffer(select.offer, debtService.debtSelect)'>
							Aceptar acuerdo
							<i class="fa fa-angle-right" aria-hidden="true"></i>
						</button>
					</div>
				</div>
				<div *ngIf="!debtService.obligations.isObligation" class="col-12 col-md-5 order-1 order-md-2">
					<div class="agree-wrap">
						<div class="agree-item-all">
							<div class="agree-item">
								<div id="accordion">
									<div class="head-agree">
										<div class="value-head">
											<p *ngIf="select.offer.nameOption == 'Opción1'">Mayor descuento</p>
											<p *ngIf="select.offer.nameOption == 'Opción2'">Plazo medio</p>
											<p *ngIf="select.offer.nameOption == 'Opción3'">Menor cuota</p>
											<h2>{{select.offer.payment | currency:'COP':'':'1.0-0' }}
												<span *ngIf="select.offer.totalPayments == 1 ">x {{ select.offer.totalPayments }} mes</span>
												<span *ngIf="select.offer.totalPayments > 1 ">x {{ select.offer.totalPayments }} meses</span>
											</h2>
											<h3>Antes <span>${{ select.offer.paymentBefore | currency:'COP':'':'1.0-0' }}</span></h3>
											<h3 class="saving">Ahorras ${{ select.offer.saving | currency:'COP':'':'1.0-0' }}</h3>
										</div>
									</div>
									<div id="collapseOne" class="info-agreement collapse show" aria-labelledby="headingOne" data-parent="#accordion">
										<div class="cell-info" *ngIf="select.offer.paymentInitial > 0">
											<p>Pago inicial</p>
											<h5>${{ select.offer.paymentInitial | currency:'COP':'':'1.0-0' }}</h5>
										</div>
										<div class="cell-info">
											<p>Pago único</p>
											<h5>${{ select.offer.paymentMonthly | currency:'COP':'':'1.0-0' }}</h5>
										</div>
										<div class="cell-info">
											<p>Total de pagos</p>
											<h5 class="others">{{ select.offer.totalPayments }}</h5>
										</div>
										<div class="cell-info">
											<p>Inicio de pagos</p>
											<h5 class="others">{{ select.offer.paymentDate | date: 'dd MMMM yyyy' }}</h5>
										</div>
										<button class="cta-program" (click)='open(select.offer.agreement.paymentAgreements, debtService.debtSelect)'>
											<img src="assets/images/agree/calendario.svg" alt="caledario de programación"/>
											Ver programación de pago
										</button>
									</div>
									<div class="plan-free" *ngIf="select.offer.totalPayments == 1">
										<img src="assets/images/agree/corona.svg" alt="caledario de programación"/>
										<p>Al hacer el pago, obtienes gratis el <span>Plan Único día</span> de Midatacrédito.</p>
									</div>			
								</div>		
							</div>
							<!-- <a class="cta-plan-select" id="seleccionarAcuerdo" (click)='saveOffer(option)'>
								Seleccionar acuerdo
								<i class="fa fa-angle-right" aria-hidden="true"></i>
							</a> -->
						</div>	
					</div>
				</div>
				<!-- Seccion obligaciones-->
				<div *ngIf="debtService.obligations.isObligation" class="col-12 col-md-4 order-1 order-md-2">
					<div class="purpleBox">
						<div class="check">
							<i class="fa fa-check-circle"></i>
						</div>
						<div class="itemPrice d-flex">
							<div class="mr-auto">Cupo actual</div>
							<p style="margin: 0%;"
								[innerHTML]="debtService.debtSelect.originalBalance | currencyPda:'COP':true:'1.2-2'"></p>
						</div>
						<div class="itemBefore d-flex">
							<div class="mr-auto">Saldo actual</div>
							<span class="sinTachado">
								<p style="margin: 0%;"
									[innerHTML]="debtService.debtSelect.balance | currencyPda:'COP':true:'1.2-2'"></p>
							</span>
						</div>
						<div class="itemBefore d-flex">
							<div class="mr-auto">Cuota mínima</div>
							<span class="sinTachado">
								<p style="margin: 0%;"
									[innerHTML]="debtService.debtSelect.minPayment | currencyPda:'COP':true:'1.2-2'"></p>
							</span>
						</div>
						<div class="dataPays">
							<div class="linePay d-flex">
								<div class="left mr-auto">Cuota mínima sugerida</div>
								<div class="left">
									<p style="margin: 0%;"
										[innerHTML]="select.offer.newMinPayment | currencyPda:'COP':true:'1.2-2'"></p>
								</div>
							</div>
							<div class="linePay d-flex">
								<div class="left mr-auto">Tiempo / plazo</div>
								<div class="left">
									{{select.offer.agreement.totalPayments}}
								</div>
							</div>
							<div class="linePay d-flex">
								<div class="left mr-auto">Tasa de Int.</div>
								<div class="left">
									{{select.offer.newInterestBalance}} % Efec. Anual
								</div>
							</div>
							<div class="linePay d-flex">
								<div class="left mr-auto">Periodo de gracia</div>
								<div class="left">
									({{select.offer.gracePeriod}}) meses</div>
							</div>
							<button class="btn-l btn-link" id="SeeProgram"
								(click)="open(select.offer.agreement.paymentAgreements, debtService.debtSelect)">
								<i class="fa fa-calculator" aria-hidden="true"></i>
								<span>Ver programación de pagos</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<app-program></app-program>
<app-sign></app-sign>