<div class="header fixed-top">
  <nav class="navbar navbar-expand-sm container-fluid">
    <a class="navbar-brand" routerLink="/">
      <img src="assets/icons/logo-l_n.svg" class="icon" alt="logo">
    </a>
    <div class="collapse navbar-collapse justify-content-end">
      <ul class="navbar-nav ">
        <li class="nav-item" *ngIf="!sesion.sesionCookie">
          <a class="nav-link dropdown-toggle btn-user b-white" (click)="openMenu()">
            ¿Qué necesitas?
            <i class="fa fa-chevron-down" aria-hidden="true"></i>
          </a>
        </li>
        <li class="nav-item dropdown" *ngIf="sesion.sesionCookie">
          <a class="nav-link dropdown-toggle btn-user" id="necesitas" (click)="openMenu()">
            Hola, {{guard.user?.nombre}}
            <i class="fa fa-chevron-down" aria-hidden="true"></i>
          </a>
          <div class="dropdown-menu userZone" aria-labelledby="necesitas">
            <div class="box-name">
              <h3>Hola, {{guard.user?.nombre}}</h3>
            </div>
            <div class="avatar">
              <img src="assets/images/avatar.svg" alt="{{user?.nombre}} {{user?.apellido}}"
                title="{{user?.nombre}} {{user?.apellido}}">
            </div>
            <div class="name">{{guard.user?.nombre}} {{guard.user?.apellido}}</div>
            <div class="products">
              <div class="cantProducts withBorder" *ngIf="debtsService.filters.state">
                <span>{{ debtsService.activeDebts}}</span><br />
                Producto<span class="normal">s</span> en mora
              </div>
              <div class="cantProducts">
                <span>{{ debtsService.activeObligation }}</span><br />
                Producto<span class="normal">s</span> en negociación
              </div>
            </div>
            <a class="dropdown-item" routerLink="/deudas"><span>Mis deudas</span><img src="assets/images/flecha.svg"
                width="21" alt="deuda" title="Mis deudas"></a>
            <a class="dropdown-item" (click)="cerrarSesion()"><span>Salir</span><img src="assets/images/flecha.svg"
                width="21" alt="salir" title="salir"></a>
          </div>
        </li>
      </ul>
    </div>
    <!-- Collapse button -->
    <button class="navbar-toggler first-button" (click)="openMenu()" type="button" data-toggle="collapse"
      data-target="#navbarSupportedContent20" aria-controls="navbarSupportedContent20" aria-expanded="false"
      aria-label="Toggle navigation">
      <div [class.open]="isOpen" class="animated-icon">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </button>
  </nav>
</div>
<div class="container submenu position-fixed" *ngIf="isOpen">
  <button type="button" class="close float-right" aria-label="Close" (click)="toggleClass()">
    <span aria-hidden="true">&times;</span>
  </button>

  <div class="dropdown-menu userZone" aria-labelledby="necesitas">
    <div class="logoMobile">
      <img src="assets/icons/logo-l_n.svg" height="25" alt="mi datacrédito | ponte al día"
        title="mi datacrédito | ponte al día">
    </div>
    <div class="avatar" *ngIf="sesion.sesionCookie">
      <img src="assets/images/avatar.svg" alt="{{guard.user?.nombre}} {{guard.user?.apellido}}"
        title="{{guard.user?.nombre}} {{guard.user?.apellido}}">
    </div>
    <div class="name" *ngIf="sesion.sesionCookie">{{guard.user?.nombre}} {{guard.user?.apellido}}</div>
    <div class="products" *ngIf="sesion.sesionCookie">
      <div class="cantProducts withBorder" *ngIf="debtsService.filters.state">
        <span>{{ debtsService.activeDebts }}</span><br />
        Producto<span class="normal">s</span> en mora
      </div>
      <div class="cantProducts">
        <span>{{ debtsService.activeObligation }}</span><br />
        Producto<span class="normal">s</span> en negociación
      </div>
    </div>
    <a class="dropdown-item" (click)="toggleClass()" routerLink="/deudas" *ngIf="sesion.sesionCookie"><span>Mis
        deudas</span><img src="assets/images/flecha.svg" width="21" alt="mis deudas" title="mis deudas"></a>
    <a class="dropdown-item" (click)="cerrarSesion(); toggleClass()" *ngIf="sesion.sesionCookie"><span>Salir</span><img
        src="assets/images/flecha.svg" width="21" alt="" title=""></a>
    <a *ngIf="!sesion.sesionCookie" class="dropdown-item" (click)="openMenu()">
      <span>¿Qué necesitas?</span>
      <img src="assets/images/flecha.svg" width="21" alt="flecha" title="flecha">
    </a>
  </div>
</div>

<div class="col-12 col-md-3 new-header" id="new-header">
  <div class="row">
    <div class="col-12 new-pages d-none" [ngClass]="{'col-md-9' : !sesion.loggedIn, 'col-md-6' : sesion.loggedIn}">
      <div class="row">
        <div *ngIf="!sesion.loggedIn" class="col-md-6 col-12 content-pages ">
          <div class="new-title">¿Cómo podemos ayudarte?</div>
        </div>
        <div class="col-12 content-pages" [ngClass]="{'col-md-6' : !sesion.loggedIn, 'col-md-12' : sesion.loggedIn}">
          <div class="new-title">Otras soluciones
          </div>
          <div class="new-title">Ayudas</div>
        </div>
      </div>
    </div>
    <div class="col-12 new-services" [ngClass]="{'logged col-md-12' : sesion.loggedIn, 'col-md-12' : !sesion.loggedIn}">
      <div class="row">
        <div class="col-12">
          <div class="new-logo">
            <img src="assets/icons/logo-w_n.svg" alt="Logo Ponte al Día" />
          </div>
          <button class="button-nav--toggle active" (click)="openMenu()">
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
        <div *ngIf="!sesion.sesionCookie" class="col-12 new-content">
          <p class="text-basic">¿Aquí un dato relevante sobre el comportamiento de las deudas de los colombianos en
            2020?</p>
          <p class="text-bold">Únete hoy a Midatacrédito, es GRATIS</p>
          <a class="new-nav-link orange" href="{{regUrl}}" rel="noopenner nofollow" id="ingresarMenu">
            Ingresar
            <i class="fa fa-chevron-right"></i>
          </a>
        </div>
        <div *ngIf="sesion.sesionCookie" class="col-12 new-content-logged">
          <p class="new-tittle-w">{{ guard.user?.nombre | titlecase }} {{ guard.user?.apellido | titlecase }}</p>

          <div class="links-logged">

            <a class="new-basic-link white" (click)="irMideudas()" id="obligacionesPorPagar">
              <i class="fa fa-calculator" aria-hidden="true"></i>
              Obligaciones por pagar
            </a>
          </div>
        </div>
        <div *ngIf="sesion.sesionCookie" class="col-12 new-close-desk">
          <button (click)="cerrarSesion()">
            Cerrar sesión
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="sesion.sesionCookie" class="col-12 new-close">
      <button (click)="cerrarSesion()">
        Cerrar sesión
      </button>
    </div>
  </div>
</div>