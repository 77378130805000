<div class="modal fade modal-program" id="modal-program" tabindex="-1" role="dialog" aria-hidden="true"
	data-backdrop="static">
	<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-body">
				<div class="content-program">
					<div class="info">
						<div class="title my-2 mb-1">
							Programación de pago con<span> {{debtService.debtSelect.nameCompany}}</span>
						</div>
						<div class="table-responsive">
							<table class="table table-striped table-sm">
								<thead>
									<tr>
										<th scope="col">No.</th>
										<th scope="col">Fecha</th>
										<th scope="col">Valor</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let item of program.program">
										<th scope="row">{{item.quota}}</th>
										<td>{{item.date | date: 'dd MMMM yyyy'}}</td>
										<td> {{ item.amount | currency:'COP':'':'1.0-0' }} </td>
									</tr>
								</tbody>
							</table>
						</div>
						<button data-dismiss="modal" aria-label="Close" class="btn btn-lg d-block mx-auto btn-secundary">
							Continuar
							<i class="arrow right"></i>
						</button>
					</div>
					<div class="img-modal">
						<img src="assets/icons/modal_ok1.svg" alt="ok">
					</div>
				</div>
			</div>
		</div>
	</div>
</div>