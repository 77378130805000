import { Injectable } from '@angular/core';
declare var dataLayer: any;

@Injectable({
  providedIn: 'root'
})
export class DataLayerService {

  constructor() { }

  /**
   * EVENTO GA4 user
   * Por cada página donde se encuentre esta función se enviará el evento denotando si el usuario esta o no logueado.
   */
  dl_userId( id_usuario: string | null, tipo_usuario: string,){
    dataLayer.push({
      'event':'ga_event',
      'name_event':'user',
      'user_id': id_usuario, 
      'user_tye': tipo_usuario
    });
  }

  /**
   * EVENTO GA4 eventos que solo tienen un parametro, que es el nombre del evento.
   * @param name_event: Nombre de evento.
   */
  dl_eventoGA4_name_event( name_event:string ){
    dataLayer.push({
      'event': 'ga_event',
      'name_event' : name_event
    });
  }

  /**
   * EVENTO GA4 - Evento que se utiliza para el footer y el header
   */
  dl_eventoGA4_menu( name_event: string, texto: string ){
    dataLayer.push({
      'event': 'ga_event',
      'name_event': name_event,
      'menu_option': texto,
    });
  }

  /**
   * EVENTO GA4 - Este evento se utiliza para los botones del home y para una oferta de goBravo.
   */
  dl_eventoGA4_button( name_event: string, texto: string ){
    dataLayer.push({
      'event': 'ga_event',
      'name_event': name_event,
      'button_name': texto,
    });
  }

  /**
   * EVENTO GA4 - step_select_payment_plan cambio a view_agreements
   */
  dl_eventoGA4_step( name_event: string, estado: string, marca: string, tipo_producto: string, mora: string ){
    dataLayer.push({
      'event': 'ga_event',
      'name_event': name_event,
      'state_agreement': estado,
      'brand_agreement': marca,
      'product_agreement': tipo_producto,
      'time_agreement': mora
    });
  }

  /**
   * EVENTO GA4 - este evento se utiliza solo para ver la programación de pago
   */
  dl_state_agreement( nombre_evento: string, estado: string, marca: string, tipo_producto: string, mora: string, valor_pactado: string | number,
                      numero_pago: string, fecha_inicio_pago: string | null ){
    dataLayer.push({
      'event': 'ga_event',
      'name_event': nombre_evento,
      'state_agreement': estado,
      'brand_agreement': marca,
      'product_agreement': tipo_producto,
      'time_late_agreement': mora,
      'deal_agreement': valor_pactado,
      'times_payment_agreement': numero_pago,
      'begin_date_agreement': fecha_inicio_pago
    });
  }

  /**
   * EVENTO GA4 - este evento se comparte en varias funciones del flujo de acuerdos.
   */
  dl_eventoGA4_print( name_event: string, estado: string, marca: string, tipo_producto: string, mora: string, valor_pactado: string | number ){
    dataLayer.push({
      'event': 'ga_event',
      'name_event': name_event,
      'state_agreement': estado,
      'brand_agreement': marca,
      'product_agreement': tipo_producto,
      'time_agreement': mora,
      'deal_agreement': valor_pactado
    });
  }

}
