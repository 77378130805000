import { Injectable } from '@angular/core';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  imgLateral: any;
  titulo: any;
  textoHTML: any;
  textBoton: any;
  enlace: any;
  enlaceBoton: any;
  typeModal: any;

  constructor() { }

  close() {
    this.imgLateral = '';
    this.titulo = '';
    this.textoHTML = '';
    this.enlace = false;
    this.enlaceBoton = '';
    this.textBoton = '';
    this.typeModal = '';
    $('#modal').modal('toggle');
  }

  open( title:any, textHTML:any, imagen:any, enlace:any, enlaceBoton:any, textBoton:any, typeModal:any) {
    $('#modal').modal('show');
    this.imgLateral = imagen;
    this.titulo = title;
    this.textoHTML = textHTML;
    this.enlace = enlace;
    this.enlaceBoton = enlaceBoton;
    this.textBoton = textBoton;
    this.typeModal = typeModal;
  }
}
