import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgramComponent } from './program.component';
import { PipeModule } from './../../../pipes/pipe.module';
@NgModule({
  declarations: [ProgramComponent],
  exports: [ProgramComponent],
  imports: [
    CommonModule,
    PipeModule
  ]
})
export class ProgramModule { }
