<header>
	<nav class="bg-primary-pda">
		<div class="nav-pda">
			<div class="nav-brand">
				<div class="nav-brand-movil" *ngIf="show_mobile_option && !sesion.sesionCookie; else desktop">
					<img src="assets/icons/Midatacredito_Logo.svg">
					<a class="btn-mobile" href="{{loginUrl}}" (click)="enviarDataLayer('Ingresar2 - mobile | Pontealdia')">Ingresar</a>
				</div>
				<ng-template #desktop>
					<img [src]="logoWhite" alt="brand" id="lBrand" routerLink="/">
				</ng-template>
			</div>
			<div class="nav-content">
				<ul class="nav-items d-none d-md-flex">
					<li class="nav-item">
						<a class="nav-link" href="{{urlMidc}}" (click)="enviarDataLayer('Midatacrédito | Pontealdia')">
							Midatacrédito
						</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="{{urlBc}}" (click)="enviarDataLayer('Buscacrédito | Pontealdia')">
							Buscacrédito
						</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="https://blog.midatacredito.com/" (click)="enviarDataLayer('Blog | Pontealdia')">
							Blog
						</a>
					</li>
				</ul>
				<div class="nav-btn" *ngIf="!sesion.sesionCookie">
					<a href="{{loginUrl}}" (click)="enviarDataLayer('Ingresar | Pontealdía')">Ingresar
						<i class="fa fa-chevron-right arrow-rotate" aria-hidden="true"></i>
					</a>
					<a (click)="openMenu()" class="hamburger">
						<i class="fa fa-bars" aria-hidden="true"></i>
					</a>
				</div>
				<div class="nav-btn" *ngIf="sesion.sesionCookie">
					<a (click)="openMenu()">
						Hola, {{guard.user?.nombre}}
						<i class="fa fa-chevron-right arrow-rotate" aria-hidden="true"></i>
					</a>
					<a (click)="openMenu()" class="hamburger">
						<i class="fa fa-bars" aria-hidden="true"></i>
					</a>
				</div>
			</div>
		</div>
	</nav>
</header>

<div class="col-12 col-md-3 new-header" id="new-header">
	<div class="row">
		<div class="col-12 new-pages d-none" [ngClass]="{'col-md-9' : !sesion.loggedIn, 'col-md-6' : sesion.loggedIn}">
			<div class="row">
				<div *ngIf="!sesion.loggedIn" class="col-md-6 col-12 content-pages ">
					<div class="new-title">¿Cómo podemos ayudarte?</div>
				</div>
				<div class="col-12 content-pages"
					[ngClass]="{'col-md-6' : !sesion.loggedIn, 'col-md-12' : sesion.loggedIn}">
					<div class="new-title">Otras soluciones</div>
					<div class="new-title">Ayudas</div>
				</div>
			</div>
		</div>
		<div class="col-12 new-services"
			[ngClass]="{'logged col-md-12' : sesion.loggedIn, 'col-md-12' : !sesion.loggedIn}">
			<div class="row">
				<div class="col-12">
					<div class="new-logo">
						<img src="assets/icons/logo-w_n.svg" alt="Logo Ponte al Día" />
					</div>
					<button class="button-nav--toggle active" (click)="openMenu()">
						<span></span>
						<span></span>
						<span></span>
					</button>
				</div>
				<div *ngIf="!sesion.sesionCookie" class="col-12 new-content">
					<p class="text-basic d-none d-md-block">¿Qué esperas para tomar el control de tu vida financiera?
					</p>
					<p class="text-bold d-none d-md-block">Únete hoy a Ponte al Día, es GRATIS</p>
					<a class="new-nav-link orange" href="{{loginUrl}}" (click)="enviarDataLayer('Ingresar mobile | Pontealdía')"
						id="ingresarMenu">
						Ingresar
						<i class="fa fa-chevron-right"></i>
					</a>
				</div>

				<div *ngIf="sesion.sesionCookie" class="col-12 new-content-logged">
					<p class="new-tittle-w">{{ guard.user?.nombre | titlecase }} {{ guard.user?.apellido | titlecase }}
					</p>
					<div class="links-logged d-none d-md-block">
						<a class="new-basic-link white" (click)="irMideudas()" id="obligacionesPorPagar">
							<i class="fa fa-calculator" aria-hidden="true"></i>
							Obligaciones por pagar
						</a>
					</div>
				</div>
				<div *ngIf="sesion.sesionCookie" class="col-12 new-close-desk">
					<button (click)="cerrarSesion()">
						Cerrar sesión
					</button>
				</div>

			</div>
		</div>
		<div *ngIf="sesion.sesionCookie" class="col-12 new-close">
			<button (click)="cerrarSesion()">
				Cerrar sesión
			</button>
		</div>
		<div class="col-12 new-services d-md-none d-block"
			[ngClass]="{'pdd-t-0' : !sesion.sesionCookie, 'pdd-t-10 only-links' : sesion.sesionCookie}">
			<div class="col-12 new-content only-links">
				<a class="new-nav-link no-color no-boton" href="{{urlMidc}}" (click)="enviarDataLayer('Midatacrédito | Pontealdia')">Midatacrédito
					<i class="fa fa-chevron-right"></i>
				</a>
				<a class="new-nav-link no-color no-boton" href="{{urlBc}}" (click)="enviarDataLayer('Buscacrédito | Pontealdia')">Buscacrédito
					<i class="fa fa-chevron-right"></i>
				</a>
				<a class="new-nav-link no-color no-boton" href="https://blog.midatacredito.com/" (click)="enviarDataLayer('Blog | Pontealdia')">Blog
					<i class="fa fa-chevron-right"></i>
				</a>
			</div>
		</div>
	</div>
</div>