import { Component, OnInit } from '@angular/core';
import { BrokerService } from './core/services/broker.service';
import { SesionService } from './core/services/sesion.service';
import { content } from './core/interface/content.interface';
import { environment } from '../environments/environment';
import { CanActiveViaAuthGuard } from './core/services/auth-guard.service';

declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'o_pntd_a13_ui';
  txt: any;
  constructor(
	  private broker: BrokerService, 
	  private sesion: SesionService,
	  private guard: CanActiveViaAuthGuard) {
    
    // const serlefin = document.createElement('script');
    // serlefin.src = 'https://m.serlefin.com/SerlefinWidget/JS/SerlefinWidget.js?v=1';
    // // serlefin.innerHTML = `<iframe src="https://m.serlefin.com/SerlefinWidget/JS/SerlefinWidget.js?v=1a"></iframe>`;
    // document.head.appendChild(serlefin);
    if(this.sesion.sesionCookie){
      // var serlefin = document.createElement('script');
      // serlefin.type = 'text/javascript';
      // serlefin.id = 'serlefinChat'
      // serlefin.innerHTML = `new SerlefinWidget(
      //   'https://m.serlefin.com/LEX/WChat',
      //   'BQr9ugMnJj7SYBBt2xPeMdmDH5NbBejnGXt7nmAzLyVUY4b5WtbzRKRBchSWuUgW',
      //   'assets/images/chat/D_Serlefin_Azul-2.png',
      //   'https://m.serlefin.com/LEX/CSS/SerlefinWidget.css', 
      //   null);`;
      // document.body.appendChild(serlefin);
    }

    if (environment.gtmPruebas) {

      const script = document.createElement('script');
      script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-5X3WN5M');`;
      document.head.appendChild(script);

      const noscript = document.createElement('noscript');
      noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5X3WN5M"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
      document.body.appendChild(noscript);
    } else {

      const script = document.createElement('script');
      script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-M8HR8J9');`;
      document.head.appendChild(script);

      const noscript = document.createElement('noscript');
      noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-M8HR8J9"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
      document.body.appendChild(noscript);

      ///// Hotjar Tracking Code for https://www.midatacredito.com 
      const scriptHotjar = document.createElement('script');
      scriptHotjar.innerHTML = `(function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:1601850,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
      document.head.appendChild(scriptHotjar);
    }
  }

  	ngOnInit() {      
    	this.broker.contenido_interface().subscribe( ( response: any ) => {
      		this.txt = response;
      		this.sesion.datos = response;
    	},(error) => {
    	});
		//Validar sesión
		this.sesion.getCookie();
    }
}
