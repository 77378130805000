import { Component, OnInit } from '@angular/core';
import { SesionService } from 'src/app/core/services/sesion.service';
import { environment } from 'src/environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BrokerService } from 'src/app/core/services/broker.service';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { UtilitiesService } from 'src/app/core/services/utilities.service';

declare var $: any;

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
	loginUrl: any;
	// private jsonUrl = 'https://images-s3-ecs-sp-latam.s3.amazonaws.com/catchupon/aliados/aliados.json';
	public dataJsonAliados: any[] = [];
	public testimonials: any[] = [];


	constructor(
		public sesion: SesionService,
		public router: Router,
		private http: HttpClient,
		private brokerService: BrokerService,
		private dlService: DataLayerService,
		public utilities: UtilitiesService) {
		this.loginUrl = environment.urlLogin;
		router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) {
			  if(val.url == "/" || val.url.toLocaleLowerCase().includes('utm')){
				this.utilities.getUtmParams();        
			  }
			}
		  });
	}

	ngOnInit() {
		this.dlService.dl_userId(null, 'anonimo');
		if (this.sesion.sesionCookie != '' && this.sesion.sesionCookie != null) {
			this.router.navigate(['/deudas']);
		}
		this.setAliados();
		this.checkBannerOfertas();
		//this.getJsonAliados();
	}

	/**
	 * Funcion para abrir o cerrar el collapse de faqs
	 */
	collpase() {
		$('.arrow-rotate').toggleClass('rotate0');
		$('.info-agreement').collapse('toggle');
		$('#seleccionarAcuerdo').toggleClass('d-flex');
	}

	/**
	 * Funcion para rotar la flecha del collapse cuando se despliga o oculta
	 * @param item 
	 */
	rotate(item: any) {
		var pru = !item;
		var pru2 = item;
		var btnArrow = $('#answer' + item);
		var menuItem = $('.btnCollapse');
		if (menuItem.attr("aria-expanded") === 'true') {
			btnArrow.addClass('rotate0');
		} else {
			btnArrow.removeClass('rotate0');
		}
	}

	/**
	 * Funcion para validar si la estrella debe ir habilitada o no en los testimoniales
	 * @param start posicion de la estrella
	 * @param qualify calificacion del testimonio
	 * @returns clase para deshabilitar o no
	 */
	validarEstrella(start: any, qualify: any) {
		if (start <= qualify) {
			return "orange";
		} else {
			return "disabled";
		}
	}
	/**
	 * Funcion que llama al archivo aliados.json que esta en el s3 para cargar los aliados y testimoniales
	 */
	setAliados() {
		this.brokerService.getAllies().subscribe({
			next: (res: any) => {
				this.dataJsonAliados = res.aliados;
				this.testimonials = res.testimonials;
			}
		});
	}

	/**
	 * Función que dirige al login de la aplicación, además envia el data layer "clic_inside_tool" cada vez que se
	 * da click en este evento.
	 * @param btn_text Se recibe el texto del botón, para asimismo enviarlo al data layer
	 * @param btn_option Recibe la url a direccionar.
	 */
	enviarDataLayer(btn_text: string, btn_option: string) {
		// GA4 - clic_inside_tool
		this.dlService.dl_eventoGA4_button('clic_inside_tool', btn_text);
		if (btn_option == 'login') {
			window.location.href = this.loginUrl;
		}

		if (btn_option == 'midc') {
			window.location.href = 'https://www.midatacredito.com/';
		}
	}

	/**
	 * 
	 * Funcion para validar si el div ya rednerizo y poder ejecutar la funcion de renderizar el banner
	 */
	checkBannerOfertas() {
		const tamano = [[728, 90], [300, 50]];
		this.utilities.addPubliById(environment.slotsBanners.home, 'div-gpt-ad-1683752952200-0', tamano);
	}

}
