export const environment = {
  production: true,
  envName: 'master',
  clientId: 'nJ6Xpc/JYe9c/GfHqqeQhhATrrBFKaoXVZ6g/CJyvJg=',
  domain: 'midatacredito.com',
  gtmPruebas: false,
  webhooks: 'https://webhooks.remarkety.com/webhooks/store/',
  APIEndpoint_authn: 'https://pontealdia.midatacredito.com/authn-ws',
  APIEndpoint_company: 'https://pontealdia.midatacredito.com/cmpnymnger-ws',
  APIEndpoint_debts: 'https://pontealdia.midatacredito.com/debtsmanager-ws',
  APIEndpoint_ngttion: 'https://pontealdia.midatacredito.com/ngttion-ws',
  APIEndpoint_bank: 'https://pontealdia.midatacredito.com/bank-ws',

  negociarObligaciones: '/pontealdia/v1/obligation/offer',
  consultarObligaciones: '/pontealdia/v1/obligation/list',
  guardarPropuestaObligacion: '/pontealdia/v1/obligation/agreement',
  consultaDeudas:    '/pontealdia/v1/debt',
  negociarDeudas: '/EngineNegotiation/getOffers',
  descargarPDF: '/companymanager/paymentAgreement',
  guardarPropuesta: '/pontealdia/v1/agreement',
  pagarLinea: '/pontealdia/v1/audit/pay',
  otraPropuesta: '/proposal/other-proposal',
  urlEscala: '/pontealdia/v1/process/preload/info',
  
  urlLogin: 'https://usuario.midatacredito.com/login?product=pntd',
  urlRegister: 'https://usuario.midatacredito.com/registro?product=pntd',
  urlSeguridad: 'https://usuario.midatacredito.com/seguridad?product=pntd',
  urlMidc : 'https://www.midatacredito.com/',
  urlBc : 'https://buscacredito.midatacredito.com/',

  // CloudFront
	enlaces_cloudFront: {
		url: 'https://d1gj9peb9ac569.cloudfront.net/', // DEMO/PROD
		pntdPath: 'pntd/prod',
		terminos_condiciones: 'tyc/terminos_y_condiciones.pdf',
		politica_privacidad: 'tyc/politica_de_tratamiento_de_datos_personales.pdf'
	},

  EvidenteEP: {
    validateQues: 'https://okta-ui-prd-co-sla-datacash.a-internal.appcanvas.net/validation/api/v1/evidente/validateQuestionsCustomer',
    validateCusto: 'https://okta-ui-prd-co-sla-datacash.a-internal.appcanvas.net/validation/api/v1/evidente/validateCustomer'
  },
  country: 'CO',
  product: 'Ponte Al Dia',
  Bank: {
    customercampaigninfo: '/ecs/datacash/bank/v1/customercampaigninfo',
    applyCampaign: '/ecs/datacash/bank/v1/campaign'
  },
	cloudFront: {
		allies: '/aliados.json',
	},
  slotsBanners : {
		home : '/22857899675/Ad_Unit_PAD_Home'
	}
};