import { Component, OnInit, HostListener } from '@angular/core';
import { SesionService } from 'src/app/core/services/sesion.service';
import { CookieService } from 'ngx-cookie-service';
import { BrokerService } from 'src/app/core/services/broker.service';
import { environment } from 'src/environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { User } from 'src/app/core/models/user.model';
import { DebtsService } from 'src/app/core/services/debts.service';
import { CanActiveViaAuthGuard } from '../../core/services/auth-guard.service';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  
  isOpen = false;
  errorUrl = false;
  url: any;
  scroll = false;
  initial = false;
  nameSesion: any;
  txt: any;
  loginUrl: string;
  regUrl: any;
  user: any = null;
  public menuAbierto = false;
  
  constructor(
    public sesion: SesionService,
    private cookieService: CookieService,
    private broker: BrokerService,
    public debtsService: DebtsService,
    public guard: CanActiveViaAuthGuard,
    public router: Router) {
      	this.loginUrl = environment.urlLogin;
      	this.regUrl = environment.urlRegister;
		//this.nameSesion = this.simular.getStorage();

		this.router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) {
				this.url = val.url;			
				if (this.url=== '/error' || '/propuesta'){
					this.errorUrl = true;
				}
				if (window.scrollY === 0) {
					if (this.url === '/deudas' || this.url === '/no-deudas'){
					// Size window
						if($(window).width() >= 768){
							$(".navbar-brand img").addClass("d-none");
							$(".navbar-brand img").removeClass("d-block");
							$(".header").css('background-color' , 'transparent');
							$(".btn-user").removeClass("b-white")
						} else {
							$(".navbar-brand img").addClass("d-block");
							$(".header").css('background-color' , 'transparent');
							$(".navbar-brand img").attr("src","assets/icons/logo-l_n.svg");
							$(".animated-icon span").css('background-color' , '#B12384');
						}
						// Size window					
					}
					if (this.url === '/error' || this.url === '/propuesta' || this.url === '/confirmar' ||this.url === '/imprimir' ){
						// this.initial = true;
						//$(".header").css('background-color' , 'transparent');
						$(".header").css('background-color' , '#B12384');
						$(".navbar-brand img").addClass("d-block");
						$(".navbar-brand img").attr("src","assets/icons/logo-w_n.svg");
						$(".btn-user").addClass("b-white")
					} 
					if (this.url === '/'){
						// Size window
						if($(window).width() >= 768){		
							$(".header").css('background-color' , 'transparent');										
							$(".navbar-brand img").attr("src","assets/icons/logo-l_n.svg ");
						} else {						
							$(".navbar-brand img").attr("src","assets/icons/logo-w_n.svg ");
							$(".animated-icon span").css('background-color' , '#fff');						
						}
						// Size window
					}
				}				
			}
		});

      this.sesion.getCookie();
      //this.validateSession();	

     }
	 /**
	  * Funcion que detecta cuando se realiza scroll y valida que cambios de logos, colores y clases debe hacer para manteener la experiencia
	  * @param event evento que obtiene al momento de hacer scroll
	  */
	@HostListener('window:scroll', ['$event'])
	onScroll(event:any) {
		console.log('2')	
		if (this.url === '/deudas' || this.url === '/no-deudas'){
			if (window.scrollY !== 0) {
				this.scroll = true;
				if($(window).width() >= 768){	
					$(".header").css('background-color' , '#B12384');
					$(".navbar-brand img").addClass("d-block");			
					$(".navbar-brand img").attr("src","assets/icons/logo-w_n.svg ");
					$(".btn-user").addClass("b-white")					
				} else {
					$(".header").css('background-color' , '#B12384');						
					$(".navbar-brand img").attr("src","assets/icons/logo-w_n.svg ");
					$(".animated-icon span").css('background-color' , '#fff');
					$(".btn-user").removeClass("b-white")
				}
			} else {
				this.scroll = false;
				if($(window).width() >= 768){
					$(".navbar-brand img").addClass("d-none");
					$(".header").css('background-color' , 'transparent');
					$(".navbar-brand img").removeClass("d-block");
					$(".btn-user").removeClass("b-white")
				} else {	
					$(".navbar-brand img").addClass("d-block");
					$(".header").css('background-color' , 'transparent');				
					$(".navbar-brand img").attr("src","assets/icons/logo-l_n.svg ");
					$(".animated-icon span").css('background-color' , '#B12384');
				}
			}
		}
		if (this.url === '/') {
			if (window.scrollY !== 0) {
				this.scroll = true;
				if($(window).width() >= 768){	
					$(".header").css('background-color' , '#B12384');					
					$(".navbar-brand img").attr("src","assets/icons/logo-w_n.svg ");
					$(".btn-user").addClass("b-white")
				} else {	
					$(".header").css('background-color' , '#B12384');					
					$(".navbar-brand img").attr("src","assets/icons/logo-w_n.svg ");
					$(".animated-icon span").css('background-color' , '#fff');
				}
			} else {
				this.scroll = false;
				if($(window).width() >= 768){		
					$(".header").css('background-color' , 'transparent');									
					$(".navbar-brand img").attr("src","assets/icons/logo-l_n.svg ");
					$(".btn-user").addClass("b-white");
				} else {						
					$(".navbar-brand img").attr("src","assets/icons/logo-w_n.svg ");
					$(".animated-icon span").css('background-color' , '#fff');
				}
			}
		}
		if (this.url === '/error' || this.url === '/propuesta' || this.url === '/confirmar' ||this.url === '/imprimir' ) {
			if (window.scrollY !== 0) {
				this.scroll = true;
				if($(window).width() >= 768){	
					$(".header").css('background-color' , '#B12384');					
					$(".navbar-brand img").attr("src","assets/icons/logo-w_n.svg ");
					$(".btn-user").addClass("b-white")
				} else {
					$(".header").css('background-color' , '#B12384');				
					$(".navbar-brand img").attr("src","assets/icons/logo-w_n.svg ");
					$(".animated-icon span").css('background-color' , '#fff');
				}
			} else {
				this.scroll = false;
				if($(window).width() >= 768){
					$(".header").css('background-color' , '#B12384');					
					$(".navbar-brand img").attr("src","assets/icons/logo-w_n.svg ");
					$(".btn-user").addClass("b-white");
				} else {	
					$(".navbar-brand img").addClass("d-block");
					$(".header").css('background-color' , 'transparent');				
					$(".navbar-brand img").attr("src","assets/icons/logo-l_n.svg ");
					$(".animated-icon span").css('background-color' , '#B12384');
				}
			}
		}
	}

	ngOnInit() {    
		$("#new-header").hide();
		setInterval( () => { 
			this.guard.validateSession(); 
		}, 450000);
	}

	/**
	 * Funcion que valida si el manu esta abierto para desplegarlo o ocultarlo
	 */
	openMenu() {
		if (!this.menuAbierto) {
			$("#new-header").slideToggle('slow');
			$("#new-header").focus();
			this.menuAbierto = true;
		} else {
			$("#new-header").slideToggle('fast');
			this.menuAbierto = false;
		}
	}

	/**
	 * Funcion que oculta el menu y llama la funcion cerrarFuncion()
	 */
	closeSession() {
		$("#new-header").hide("fast");
		this.menuAbierto = false;
		this.sesion.getCookie();
		this.cerrarSesion();
	}

	/**
	 * Funcion para validar si el menu esta abierto o cerrado y hacer el proceso contrario
	 */
	toggleClass() {
		this.isOpen = !this.isOpen;
	}
	/**
	 * Funhcion para cerrar el menu y redireccionar a la landing de deudas / no deudas
	 */
	irMideudas() {
		$("#new-header").slideToggle('fast');
		this.menuAbierto = false;
		this.router.navigate(['/deudas']);
	}

	/**
	 * Funcion que llama al endpoint authn/signOff para cerrar la sesion limpiar el local storage, la cookie y redireccionar al home
	 */
	cerrarSesion() {
		const body = {
			clientId: environment.clientId,
			idSession: this.sesion.sesionCookie,
			country: environment.country
		};
		this.cookieService.delete('IDSESSIONMDC', this.sesion.sesionCookie, environment.domain);
		this.broker.cerrar_sesion(body).subscribe((response: any) => {
			window.localStorage.clear();
		}, error =>{
		});
		setTimeout(() => {
			this.cookieService.delete('IDSESSIONMDC', this.sesion.sesionCookie, environment.domain);
			this.sesion.sesionCookie = null;
			window.location.href = '/';
		}, 500);
	}
}
