import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree,Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { BrokerService } from './broker.service';
import { DebtsService } from 'src/app/core/services/debts.service';
import { UtilitiesService } from './utilities.service';

@Injectable({
  providedIn: 'root'
})
export class HomeGuard implements CanActivate {
  constructor(
    public broker: BrokerService, 
    public cookieservice: CookieService,
    public router: Router,
		public debtsService: DebtsService,
    public utilities: UtilitiesService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return new Observable<boolean>(obs => {
        const cookie = this.cookieservice.get('IDSESSIONMDC');
        if(cookie != ''){
          
          this.broker.consultarDeudas().subscribe((response: any) => {
            this.debtsService.customerDebts = response;
            this.utilities.getUtmParams(false);
            if (this.debtsService.customerDebts.responseObject != null) {
              if (this.debtsService.customerDebts.responseObject.debts.length == 0 || this.debtsService.customerDebts.responseObject == null) {
                this.router.navigateByUrl('/no-deudas'); 
                obs.next(false);
              }else{
                this.debtsService.filteredItems = Object.assign([], this.debtsService.customerDebts.responseObject.debts);
                this.debtsService.filteredOther = Object.assign([], this.debtsService.customerDebts.responseObject.debtsInHistory);
                this.debtsService.debt = response.responseObject.debts;
                this.debtsService.debtsHistory = response.responseObject.debtsInHistory;
                this.debtsService.debtLength = response.responseObject.countDebtsArrears;
                this.router.navigateByUrl('/deudas'); 
                obs.next(false);
              }
            }else{
              obs.next(true);
            }
          },
            (error) => {
              this.router.navigateByUrl('/deudas'); 
              obs.next(false);
            });
        }else{
          obs.next(true);
        }
      });
  }
  
}

