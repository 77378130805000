<div class="container-fluid footer">
  <div class="container">
    <div class="row logo">
      <div class="col-10 mx-auto col-sm-4 pb-1 pb-md-4 pt-1 pt-md-3 pr-5">
        <img class="icon" src="assets/icons/logo-w_n.svg" alt="Score puntaje credito midatacredito buscacredito"
          title="Ponte al día de Midatacrédito" />
      </div>
      <div class="mapsite col-12 col-sm-8 mb-3 mb-md-1">
        <ul class="row list-group list-group-flush d-sm-none">
          <li class="list-group-item">
            <button class="btn btn-link" data-toggle="collapse" data-target="#midatacash" aria-expanded="false"
              aria-controls="midatacash">
              {{sesion.datos.footer?.seccion1?.titulo}}
            </button>
            <div id="midatacash" class="collapse">
              <!-- <a href="" class="mapsite_link">Qué es Ponte al día</a> -->
              <a href={{politicasPrivacidad}}
                (click)="enviar_dataLayer(sesion.datos.footer?.seccion1?.textos?.txt2)"
                class="mapsite_link">{{sesion.datos.footer?.seccion1?.textos?.txt2}}</a>
              <a href={{terminosycondiciones}}
                (click)="enviar_dataLayer(sesion.datos.footer?.seccion1?.textos?.txt3)"
                class="mapsite_link">{{sesion.datos.footer?.seccion1?.textos?.txt3}}</a>
            </div>
          </li>
          <li class="list-group-item">
            <button class="btn btn-link" data-toggle="collapse" data-target="#faqfo" aria-expanded="false"
              aria-controls="faqfo">
              {{sesion.datos.footer?.seccion2?.titulo}}
            </button>
            <div id="faqfo" class="collapse">
              <a href={{sesion.datos.footer?.seccion2?.links?.url1}}
                (click)="enviar_dataLayer(sesion.datos.footer?.seccion2?.textos?.txt1)"
                class="mapsite_link">{{sesion.datos.footer?.seccion2?.textos?.txt1}}</a>
              <a href={{sesion.datos.footer?.seccion2?.links?.url2}}
                (click)="enviar_dataLayer(sesion.datos.footer?.seccion2?.textos?.txt2)"
                class="mapsite_link">{{sesion.datos.footer?.seccion2?.textos?.txt2}}</a>
              <a href={{sesion.datos.footer?.seccion2?.links?.url4}}
                (click)="enviar_dataLayer(sesion.datos.footer?.seccion2?.textos?.txt4)"
                class="mapsite_link">{{sesion.datos.footer?.seccion2?.textos?.txt4}}</a>
            </div>
          </li>
          <li class="list-group-item">
            <button class="btn btn-link" data-toggle="collapse" data-target="#somos" aria-expanded="false"
              aria-controls="somos">
              Otras soluciones
            </button>
            <div id="somos" class="collapse">
              <a href="https://www.midatacredito.com/"
                (click)="enviar_dataLayer('Historial de crédito')" class="mapsite_link">Historial de crédito</a>
              <!-- <a href="https://www.midatacredito.com/verifica" class="mapsite_link">Midactacrédito Verifica</a> -->
            </div>
          </li>
        </ul>
        <div class="footer-full d-none d-sm-block">
          <div class="row justify-content-around ">
            <div class="col-sm-4">
              <div class="title">{{sesion.datos.footer?.seccion1?.titulo}}</div>
              <!-- <a href="" class="mapsite_link">Qué es buscadrédito</a> -->
              <a href={{politicasPrivacidad}} title="Política de privacidad"
                (click)="enviar_dataLayer(sesion.datos.footer?.seccion1?.textos?.txt2)"
                class="mapsite_link">{{sesion.datos.footer?.seccion1?.textos?.txt2}}</a>
              <a href={{terminosycondiciones}} title="Términos y condiciones"
                (click)="enviar_dataLayer(sesion.datos.footer?.seccion1?.textos?.txt3)"
                class="mapsite_link">{{sesion.datos.footer?.seccion1?.textos?.txt3}}</a>
            </div>
            <div class="col-sm-4">
              <div class="title">{{sesion.datos.footer?.seccion2?.titulo}}</div>
              <a href={{sesion.datos.footer?.seccion2?.links?.url1}}
                (click)="enviar_dataLayer(sesion.datos.footer?.seccion2?.textos?.txt1)"
                class="mapsite_link">{{sesion.datos.footer?.seccion2?.textos?.txt1}}</a>
              <a href={{sesion.datos.footer?.seccion2?.links?.url2}}
                (click)="enviar_dataLayer(sesion.datos.footer?.seccion2?.textos?.txt2)"
                class="mapsite_link">{{sesion.datos.footer?.seccion2?.textos?.txt2}}</a>
              <a href={{sesion.datos.footer?.seccion2?.links?.url4}}
                (click)="enviar_dataLayer(sesion.datos.footer?.seccion2?.textos?.txt4)"
                class="mapsite_link">{{sesion.datos.footer?.seccion2?.textos?.txt4}}</a>
            </div>
            <div class="col-sm-4">
              <div class="title">Otras soluciones</div>
              <a href="https://www.midatacredito.com/"
                (click)="enviar_dataLayer('Historial de crédito')" class="mapsite_link">Historial de crédito</a>
              <!-- <a href="https://www.midatacredito.com/verifica" class="mapsite_link">Midactacrédito Verifica</a> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="cont-copy">
      <div class="copyright">
        <img src="assets/icons/logo-datacredito.svg" alt="datacredito">
      </div>
      <div class="sic">
        <p>VIGILADO</p>
        <img src="assets/icons/logo_SIC_blanco.png" alt="sic">
      </div>
      <p class="version">
        V.a13-2.3
      </p>
    </div>
    <p class="pBottom">{{year}} Experian Colombia S.A. © Derechos reservados. NIT 900.422.614-8. Carrera 7 No. 76 – 35
      |Bogotá D.C., Colombia. Email: <a
      href="mailto:servicioalciudadano@experian.com?subject=Servicio al ciudadano - Pontealdía">servicioalciudadano@experian.com</a></p>
  </div>
</div>