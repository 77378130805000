import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { SesionService } from 'src/app/core/services/sesion.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor( private dl_service: DataLayerService, public sesion: SesionService, public router: Router ) { }

  ngOnInit() {
    // GA4 - user
		if(this.sesion.sesionCookie != '' && this.sesion.sesionCookie != null) {	
      this.dl_service.dl_userId( '.', 'logueado' );
		} else {
      this.dl_service.dl_userId( null, 'anonimo' );
    }
  }

}
