<div class="error py-5 mt-5">
        <div class="container">
          <div class="row">
            <div class="col-md-10 col-xs-12 mx-auto">
              <div class="row">
                    <div class="col-xs col-md-5 p-0">
                            <img class="w-100" src="assets/images/error.svg" alt="error">
                        </div>
                <div class="col-12 col-md-7">
                  <div class="title">Lo sentimos</div>
                  <strong>Algo salió mal</strong>
                  <p class="mt-3">Algunos de nuestros servicios no están funcionando actualmente.</p>
                  <p>Puedes actualizar la página o volver a intentarlo más tarde.</p>
                  <div class="row">
                    <!-- <div class="col-4">
                        <button class="btn btn-outline" routerLink="/"> Volver <i class="arrow right"></i></button>
                    </div> -->
                    <div class="col-12 col-md-4" *ngIf="sesionC">
                      <button class="btn btn-orange" id="goHome" routerLink="/">
                        <span>Ir al inicio</span>
                        <i class="arrow right"></i>
                      </button>
                        <!-- <button class="btn btn-secundary" routerLink="/">Ir al inicio <i class="arrow right"></i></button> -->
                    </div>
                    <div class="col-12 col-md-4" *ngIf="!sesionC">
                      <button class="btn btn-orange" id="goHome-2" routerLink="/">
                        <span>Ir al inicio</span>
                        <i class="arrow right"></i>
                      </button>
                      <!-- <button class="btn btn-secundary" routerLink="/">Ir al inicio <i class="arrow right"></i></button> -->
                  </div>
                  </div>
                </div>
                
              </div>
            </div>
            
          </div>
        </div>
      </div>