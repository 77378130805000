import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { content } from '../interface/content.interface';
import { environment } from 'src/environments/environment';
import { BrokerService } from './broker.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SesionService {

	loggedIn: any;
	datos = {} as content;
	sesionCookie: any;
	infoComplete: any;
	user: any;

	constructor(
		public cookieService: CookieService,
		private broker: BrokerService,
		public router: Router
		) {
			this.getCookie();
		}

		getCookie() {
			this.sesionCookie = this.cookieService.get('IDSESSIONMDC');
		}
}
