<div class="modal loading understood" tabindex="-1" role="dialog" id="understood">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">        
        <div class="modal-content">
            <img *ngIf="select.state" src="assets/images/backgrounds/fondo_purple.svg" alt="bg-ok" class="bg-ok"/>
            <div class="modal-body">
                <div class="container-fluid px-0 px-md-3">
                    <div class="cerrar" title="Cerrar" (click)='closeUnder()'>x</div>
                    <div class="row">
                        <div class="col-12 col-md-7 order-1 order-md-0 align-self-center">
                            <div class="principal" *ngIf="select.state">Ante cualquier duda, puedes <span>ponerte en contacto</span> mediante los canales de atención del {{broker.nameCompanyAccept}}.</div>
                            <div class="centralText"  [ngClass]="select.state ? '' : 'green'">¡Tú acuerdo de pago ha sido confirmado por la entidad!</div>
                            <div class="entendido" (click)='closeUnder()'[ngClass]="select.state ? '' : 'orange'" >¡Entendido! <i class="fa fa-angle-right" aria-hidden="true"></i></div> 
                        </div>
                        <div class="col-12 col-md-5 order-0 order-md-1 align-self-center">
                            <img *ngIf="debtService.debtSelect" src="assets/images/all/Pay_ok.svg" class="imgConfirm" alt="ok" title="">
                            <img *ngIf="select.state" src="assets/images/back_understood.svg" class="imgConfirm" alt="back" title="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>