import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { OfferSelectedService } from 'src/app/core/services/offer-selected.service';
import { DealService } from 'src/app/core/services/deal.service';
import { ProgramService } from 'src/app/core/services/program.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { DebtsService } from 'src/app/core/services/debts.service';
import { BrokerService } from 'src/app/core/services/broker.service';
import { SignService } from 'src/app/core/services/sign.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { DataLayerService } from 'src/app/core/services/data-layer.service';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})
/**
 * Componente que se comporta como una pagina que su ruta es localhost:4200/imprimir, la cual sirve para revisar la informacion del acuerdo de pago de la deuda
 * La informacion que usa para mostrar la informacion la obtiene del llamando del servicio /companymanager/paymentAgreement
 */
export class PrintComponent implements OnInit {
  selectData: any;
  loadingDownloadFile: boolean = false;
  base64:any;
  public obligation: boolean = false;
  public descargando: boolean = false;
  public errorDescarga: boolean = false;
  constructor(
    public router: Router,
    public select: OfferSelectedService,
    public agree: DealService,
    public program: ProgramService,
    public sesion: SesionService,
    public debtService: DebtsService,
    public broker: BrokerService,
    public sign: SignService,
    public sanitizer:DomSanitizer,
    private dlService: DataLayerService ) {

  }

  /**
   * Se revisar que la variable select.state no llegue vacia para poder mostrar la informacion en caso de que llegue vacia lo devuelve a /deudas
   */
  ngOnInit() {
    this.sign.openUnderstood();
    if(this.select.state == null){
      this.router.navigate(['/deudas']);
    }
    // GA4 - user
		this.dlService.dl_userId( '.', 'logueado' );
  }


  /**
   * Funcion para vaciar las variables de de deuda seleccionada y el acuerdo de pago y lo redirige a /deudas,
   */
  backAdmin() {
    this.select.state = null;
    this.debtService.debtSelect = null;
    this.router.navigate(['/deudas']);
  }

  /**
   * Función para el envio de los diferentes data layer que se envian en el componente, por medio de la función "dl_state_agreement"
   * @param instruccion Brinda la instrucción sobre cual data layer se enviará
   */
  tracking_datalayer( instruccion:string ){
    const id = this.select.de;
    const lista_items: [] = this.debtService.debt;
    const producto: any = lista_items.find( ({ de }) => de === id);
    const valor_pactado = new Intl.NumberFormat('es-CO').format(producto.arrangementAmount);
    let nombre_evento = '';

    if ( instruccion == 'pagarLinea' ){ nombre_evento = 'clic_pay_agreement'; }
    if ( instruccion == 'descargar' ){ nombre_evento = 'clic_pdf_agreement'; }

    this.dlService.dl_eventoGA4_print( nombre_evento, producto.accountStatuts, producto.nameCompany, producto.nameProduct, `${producto.delinquencyInDays} dias`, valor_pactado );
  }

  /**
   * Función que retorna la fecha en donde se inicio la deuda
   * @param fecha fecha actual
   * @param dias días que tiene la deuda
   */
  restarDias(fecha:any, dias:any) {
		fecha.setDate(fecha.getDate() + dias);
		return fecha;
	}

  /**
   * Funcion que envia la deuda seleccionada cambiando el body para que el servicio /companymanager/paymentAgreement retorne el base64 del pdf
   * al tener el base 64 del pdf se le asigna el href de un <a> con id="descargaPDF" oculto y se realiza el click automaticamente para descarga el pdf
   * Al hacer click muestra el spinner con la variable descargando = true y oculta el boton, cuando termina de llamr el servicio oculta el spinner y muestra el boton
   * Si el servicio retorna un error muestra una caja de error con la variable errorDescarga = true.
   * Además se hace envió del data layer "clic_pdf_agreement".
   * @param deuda Deuda seleccionanda para descargar el pdf.
   */
  // descargarPDF(deuda: any) {
  //   this.tracking_datalayer('descargar');
  //   this.descargando = true;
  //   this.errorDescarga = false;

  //   if (this.debtService.obligations.isObligation) {
  //     this.agree.type = 2;
  //   }
  //   this.loadingDownloadFile = true;
  //   const data = {
  //     de: deuda,
  //     country: environment.country,
  //     project: environment.product,
  //     idSession: this.sesion.sesionCookie,
  //     type: this.agree.type
  //   };

  //   this.broker.descargarPDF(data).subscribe((pdf: any) => {
  //     this.loadingDownloadFile = false;
  //     this.base64 = this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64, ' + pdf.pdfPayAgree);
  //     let downloadLink = document.getElementById("descargaPDF");
  //     setTimeout(() => {
  //       if(downloadLink != null){
  //         downloadLink.click();
  //         this.descargando = false;
  //       }
  //     }, 500);
  //   }, error => {
  //     this.descargando = false;
  //     this.errorDescarga = true;
  //   });

  // }

  /**
   * Funcion que recibe la url del aliado para abrirla en una nueva ventana
   * adicional se llama el servicio /pontealdia/v1/audit/pay
   * Finalmente se envía datalayer "clic_pay_agreement". A futuro cambiará por "step_pay_agreement"
   * @param url Url del aliado para realizar el pago
   */
  pagarLinea(url:string) {
    this.tracking_datalayer( 'pagarLinea' );
    window.location.href = url;
    const data2 = {
      sessionId: this.sesion.sesionCookie,
      nit: this.broker.dataLinea
    };
    this.broker.pagarLinea(data2).subscribe((respuesta: any) => {
    }, error => {

    });
  }

  /**
   * Funcion para verificar si el boton de acuerdo de pago se deshabilita
   * @param date Fecha del acuerdo para pagar
   * @param index Recibe el index de la posicion del acuerdo de pago
   * @returns Si la fecha de pago esta en el mes y el dia es menor o igual al dia consultado habilita el boton, y si el dia consultado es mayor habilita el boton del siguiente mes 
   */

  checkDate(date : Date, index : number){
    let today = new Date();
    let datePay = new Date(date);
    //Valida que el mes de pago y el mes actual sean iguales
    if(datePay.getMonth() == today.getMonth() && datePay.getFullYear() <= today.getFullYear()){ 
      //Valida que el dia actual sea menor o igual al dia de pago
      if(today.getDate() <= datePay.getDate()){
        return false;
      }
      return true;
    }
    //Si las validaciones anteriores no coinciden revisa que exista una fecha anterior para saber si se puede habilitar el mes siguiente
    if(index-1 != -1){
      let dateBefore = new Date(this.select.state.agreement.paymentAgreements[index-1]['agreementDate']);
      //Valida que el mes de la fecha anterior sea igual al actual para habilitar el mes siguiente
      if(dateBefore.getMonth() == today.getMonth() && datePay.getFullYear() <= today.getFullYear()){
        return false;
      }
      return true;
    }else{
      if(datePay.getMonth() >= today.getMonth() && datePay.getFullYear() <= today.getFullYear()){
        return false;
      }
      return true;
    }
  }
}