import { CurrencyPdaPipe } from './currency-pda.pipe';
import { NgModule } from '@angular/core';
@NgModule({
  imports: [],
  declarations: [ 
    CurrencyPdaPipe
  ],
  exports: [
    CurrencyPdaPipe
  ]
})
export class PipeModule{}