<div class="container-fluid faq-main">
    <div class="faqHeader">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-7">
                    <div class="titFaqGral">
                        <div class="faqTitle">Preguntas frecuentes</div>
                        <div class="faqDescription">¿No encontraste tu respuesta?, también puedes escribirnos: <a href="#">fqas@pontealdíamidatacredito.com</a></div>
                    </div>
                </div>
                <div class="col-12 col-md-5 text-center d-none d-md-block">
                    <img src="assets/images/all/Preguntas_mobile.svg" class="faqImg" alt="Preguntas Frecuentes" title="Preguntas Frecuentes">  
                </div>
            </div>
        </div>
    </div>
	<div class="container">
        <div class="row justify-content-center faqGroup">
			<div class="col-12 col-md-3">
                <div class="group">Mis opciones para ponerme al día</div>
                <div class="line"></div>
            </div>
            <div class="col-12 col-md-9">
                <div class="accordion" id="faqAccordion">
                    <div class="card-header faqQuestion" id="faq1a">
                        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapse1a" aria-expanded="false" aria-controls="collapse1a">
                            <i class="fa fa-plus" aria-hidden="true"></i>¿Por qué no aparecen todas las deudas que tengo con las entidades financieras? 
                        </button>
                    </div>                        
                    <div id="collapse1a" class="collapse" aria-labelledby="faq1a" data-parent="#faqAccordion">
                        <div class="card-body faqAnswer">
                            Sólo podrás visualizar las deudas que las entidades aliadas reportaron a Ponte al Día, esto no quiere decir que no tengas deudas con ninguna entidad, para lo que te sugerimos que consultes tu historia de crédito en <a href="https://www.midatacredito.com">www.midatacredito.com</a>. 
                        </div>
                    </div>
                    <div class="card-header faqQuestion" id="faq1b">
                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse1b" aria-expanded="false" aria-controls="collapse1b">
                            <i class="fa fa-plus" aria-hidden="true"></i>¿Qué debo hacer si tengo una deuda reportada en Ponte al Día?
                        </button>
                    </div>                        
                    <div id="collapse1b" class="collapse" aria-labelledby="faq1b" data-parent="#faqAccordion">
                        <div class="card-body faqAnswer">
                            Debes iniciar sesión en Ponte al Día y una vez conozcas el detalle de tu deuda puedes empezar a negociarla a través de la misma plataforma; se te presentarán varias opciones de negociación sobre las que podrás escoger la que más se acomode a tu situación financiera
                        </div>
                    </div>
                    <div class="card-header faqQuestion" id="faq1c">
                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse1c" aria-expanded="false" aria-controls="collapse1c">
                            <i class="fa fa-plus" aria-hidden="true"></i>¿Cuál es la mejor opción para mí?
                        </button>
                    </div>                        
                    <div id="collapse1c" class="collapse" aria-labelledby="faq1c" data-parent="#faqAccordion">
                        <div class="card-body faqAnswer">
                            Sólo tú puedes decidir cuál es la mejor opción para pagar tu deuda. Al revisar las opciones disponibles, asegúrate de elegir una opción y un plan de pago que puedas cumplir. Evalúa la cantidad de cuotas con las que puedes ponerte al día con tu deuda de acuerdo con las opciones que te da la entidad. Si pagar la totalidad de la deuda no es una opción y la entidad te ofrece negociar tu deuda, puedes proponer una oferta de pago.
                        </div>
                    </div>
                    <div class="card-header faqQuestion" id="faq1d">
                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse1d" aria-expanded="false" aria-controls="collapse1d">
                            <i class="fa fa-plus" aria-hidden="true"></i>¿Qué pasa si no tengo dinero en este momento?
                        </button>
                    </div>                        
                    <div id="collapse1d" class="collapse" aria-labelledby="faq1d" data-parent="#faqAccordion">
                        <div class="card-body faqAnswer">
                            Incluso si no cuentas con dinero suficiente para saldar tu deuda hoy, puedes proponer la fecha de inicio de pago de tu deuda según las opciones que te dé la entidad. Inicia sesión y conoce las opciones disponibles.
                        </div>
                    </div>
                    <div class="card-header faqQuestion" id="faq1e">
                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse1e" aria-expanded="false" aria-controls="collapse1e">
                            <i class="fa fa-plus" aria-hidden="true"></i>¿Cuánto debería ofrecer para ponerme al día con mi deuda pagando menos?                      
                        </button>
                    </div>                        
                    <div id="collapse1e" class="collapse" aria-labelledby="faq1e" data-parent="#faqAccordion">
                        <div class="card-body faqAnswer">
                            La propuesta que realices para pagarla debe estar acorde a un porcentaje razonable del saldo adeudado. Si tu propuesta es muy baja, podríamos hacerte tres contrapropuestas. Puedes optar por aceptar una de nuestras contrapropuestas o hacernos otra propuesta.
                        </div>
                    </div>
                    <div class="card-header faqQuestion" id="faq1f">
                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse1f" aria-expanded="false" aria-controls="collapse1f">
                            <i class="fa fa-plus" aria-hidden="true"></i>¿Qué es una contrapropuesta?                     
                        </button>
                    </div>                        
                    <div id="collapse1f" class="collapse" aria-labelledby="faq1f" data-parent="#faqAccordion">
                        <div class="card-body faqAnswer">
                            Una contrapropuesta es una alternativa de negociación de tu deuda que te puede aparecer si no se aceptó tu propuesta original. Puedes recibir hasta 3 contraofertas cada vez. Para ponerte al día con tu deuda, puedes aceptar una de estas contrapropuestas o hacer una propuesta diferente.
                        </div>
                    </div>
                    <div class="card-header faqQuestion" id="faq1g">
                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse1g" aria-expanded="false" aria-controls="collapse1g">
                            <i class="fa fa-plus" aria-hidden="true"></i>¿A cuántos pagos puedo pagar mi deuda?                    
                        </button>
                    </div>                        
                    <div id="collapse1g" class="collapse" aria-labelledby="faq1g" data-parent="#faqAccordion">
                        <div class="card-body faqAnswer">
                            El número de meses en los que te puedes poner al día con tu deuda varía para cada obligación y de lo que te puede ofrecer la entidad con la que tienes la deuda. El sistema no permite programar pagos que superen el rango permitido. Propón montos de pago realistas de acuerdo a tu estado actual. Asegúrate que puedas pagar todas tus cuotas programadas mensualmente.
                        </div>
                    </div>
                </div>  
            </div>
        </div>
        <div class="separate"></div>
        <div class="row justify-content-center faqGroup">
            <div class="col-12 col-md-3">
                <div class="group">Pagos</div>
                <div class="line"></div>
            </div>
            <div class="col-12 col-md-9">
                <div class="accordion" id="faqAccordion2">
                    <div class="card-header faqQuestion" id="faq2a">
                        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapse2a" aria-expanded="false" aria-controls="collapse2a">
                            <i class="fa fa-plus" aria-hidden="true"></i>¿En dónde puedo hacer el pago de mi deuda?
                        </button>
                    </div>                        
                    <div id="collapse2a" class="collapse" aria-labelledby="faq2a" data-parent="#faqAccordion2">
                        <div class="card-body faqAnswer">
                            Una vez confirmes un acuerdo de pago, la entidad te indicará a través de este, sus medios de pago disponibles para que te pongas al día con la deuda.
                        </div>
                    </div>
                    <div class="card-header faqQuestion" id="faq2b">
                        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapse2b" aria-expanded="false" aria-controls="collapse2b">
                            <i class="fa fa-plus" aria-hidden="true"></i>¿Aumentarán tus pagos alguna vez sin tu conocimiento?
                        </button>
                    </div>                        
                    <div id="collapse2b" class="collapse" aria-labelledby="faq2b" data-parent="#faqAccordion2">
                        <div class="card-body faqAnswer">
                            Tus montos de pago no podrán cambiar, las fechas de pago y los montos quedan establecidos en el momento del acuerdo. La programación puede hacer que todos los pagos sean de igual cantidad o que tengan montos mensualmente diferentes durante el período de pago, todas las fechas de vencimiento y los montos están detallados en la página Resumen, por lo que no se podrán realizar cambios en los pagos programados.
                        </div>
                    </div>                    
                </div>  
            </div>
        </div>
        <div class="separate"></div>
        <div class="row justify-content-center faqGroup">
            <div class="col-12 col-md-3">
                <div class="group">Resolución y acuerdo</div>
                <div class="line"></div>
            </div>
            <div class="col-12 col-md-9">
                <div class="accordion" id="faqAccordion3">
                    <div class="card-header faqQuestion" id="faq3a">
                        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapse3a" aria-expanded="false" aria-controls="collapse3a">
                            <i class="fa fa-plus" aria-hidden="true"></i>¿Aumentará alguna vez el valor acordado?
                        </button>
                    </div>                        
                    <div id="collapse3a" class="collapse" aria-labelledby="faq3a" data-parent="#faqAccordion3">
                        <div class="card-body faqAnswer">
                            Cuando se aprueba una propuesta y se ha acordado el pago, el valor del acuerdo no aumentará
                        </div>
                    </div>
                    <div class="card-header faqQuestion" id="faq3b">
                        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapse3b" aria-expanded="false" aria-controls="collapse3b">
                            <i class="fa fa-plus" aria-hidden="true"></i>¿Qué pasa si me atraso en un pago de mi acuerdo de pago?
                        </button>
                    </div>                        
                    <div id="collapse3b" class="collapse" aria-labelledby="faq3b" data-parent="#faqAccordion3">
                        <div class="card-body faqAnswer">
                            Si prevés retrasarte con un pago, ponte en contacto con tu entidad antes de que se ejecute el pago programado para evaluar qué opciones hay disponibles para ti
                        </div>
                    </div>
                    <div class="card-header faqQuestion" id="faq3c">
                        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapse3c" aria-expanded="false" aria-controls="collapse3c">
                            <i class="fa fa-plus" aria-hidden="true"></i>¿Qué métodos de pago son aceptados?
                        </button>
                    </div>                        
                    <div id="collapse3c" class="collapse" aria-labelledby="faq3c" data-parent="#faqAccordion3">
                        <div class="card-body faqAnswer">
                            Los métodos de pagos varían de acuerdo a cada entidad.
                        </div>
                    </div>
                    <div class="card-header faqQuestion" id="faq3d">
                        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapse3d" aria-expanded="false" aria-controls="collapse3d">
                            <i class="fa fa-plus" aria-hidden="true"></i>¿Cómo sé si Ponte al día recibió mi pago y se aplicó a mi obligación?
                        </button>
                    </div>                        
                    <div id="collapse3d" class="collapse" aria-labelledby="faq3d" data-parent="#faqAccordion3">
                        <div class="card-body faqAnswer">
                            Para verificar el estado de tu pago, vuelve a iniciar sesión en el sitio y visita tu página de resumen. Por último, también puedes confirmar con tu Tarjeta de crédito o débito utilizado para asegurarte de que el pago se haya realizado                      
                        </div>
                    </div>
                    <div class="card-header faqQuestion" id="faq3e">
                        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapse3e" aria-expanded="false" aria-controls="collapse3e">
                            <i class="fa fa-plus" aria-hidden="true"></i>¿Qué pasa después de realizar todos mis pagos? ¿Puedo obtener un paz y salvo?
                        </button>
                    </div>                        
                    <div id="collapse3e" class="collapse" aria-labelledby="faq3e" data-parent="#faqAccordion3">
                        <div class="card-body faqAnswer">
                            Una vez que se reciba la confirmación de todos los pagos por parte de la entidad, te enviaremos un mail de confirmación sobre el estado de tu deuda. Para solicitar el paz y salvo lo debes tramitar directamente con la entidad con la que tenías la deuda.  
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    </div>
</div>