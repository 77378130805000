import { Component, OnInit } from '@angular/core';
import { OfferSelectedService } from 'src/app/core/services/offer-selected.service';
import { DealService } from 'src/app/core/services/deal.service';
import { ProgramService } from 'src/app/core/services/program.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { DebtsService } from 'src/app/core/services/debts.service';
import { Router } from '@angular/router';
import { SignService } from 'src/app/core/services/sign.service';
import { BrokerService } from 'src/app/core/services/broker.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { environment } from 'src/environments/environment';
import { CanActiveViaAuthGuard } from '../../core/services/auth-guard.service';
import { DataLayerService } from 'src/app/core/services/data-layer.service';

declare var $: any;
declare var require: any

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})

export class ConfirmComponent implements OnInit {
  public obligation: boolean = false;
  alertCheck = false;
  alertError = false;
  constructor(
    public select: OfferSelectedService,
    public agree: DealService,
    public program: ProgramService,
    public sesion: SesionService,
    public debtService: DebtsService,
    public router: Router,
    public sign: SignService,
    public broker: BrokerService,
    public guard: CanActiveViaAuthGuard,
    public loading: LoadingService,
	private dl_service: DataLayerService) { 
    }

  ngOnInit() {
	// GA4 - user
	this.dl_service.dl_userId( '.', 'logueado' );
   }

  /**
	 * Muestra la programación del pago con la entidad
	 * Además se envia una etiqueta de GA4, nombrada "clic_select_agreement"
	 * @param data información del pago
	 * @param item información completa de la deuda
	 */
  open(data: any, item: any) {
	const hoy = new Date();
	const fecha = this.restarDias(hoy, - item.delinquencyInDays);
	this.dl_service.dl_state_agreement('clic_dates_payments_agreement', item.accountStatuts, item.nameCompany, item.nameProduct, `${item.delinquencyInDays} dias`, new Intl.NumberFormat('es-CO').format(item.totalDebt), item.totalPayment, fecha.toLocaleDateString());
    this.program.open(data);
  }

	/**
	 * Función que retorna la fecha en donde se inicio la deuda
	 * @param fecha fecha actual
	 * @param dias días que tiene la deuda
	 */
	restarDias(fecha:any, dias:any) {
		fecha.setDate(fecha.getDate() + dias);
		return fecha;
	}

  /**
   * Esta función regresa a la página de la propuesta
   * Además envia un evento de GA4 por medio del data layer "cancel_agreement"
   */
  back() {
	this.dl_service.dl_eventoGA4_name_event('cancel_agreement');
    this.router.navigate(['/propuesta']);
  }

	openSignature() {
		this.sign.openSign();
	}

  	confirmOffer(item: any, _data: any) {
		// GA4 - accept_agreement
		this.dl_service.dl_eventoGA4_print('accept_agreement', _data.accountStatuts, _data.nameCompany, _data.nameProduct, `${_data.delinquencyInDays} dias`, new Intl.NumberFormat('es-CO').format(_data.totalDebt));
    	this.loading.open('Por favor espera un momento ...', '', 'assets/images/animations/loading_clock.json', 'animation', true);
    	this.select.offer = item;
    	//this.broker.account = this.select.offer.agreement.idUserProduct
    		const data = {
			sessionID: this.sesion.sesionCookie,
			de: this.debtService.debtSelect.de,
			integration: this.agree.integration,
			type: this.debtService.type,
			offer: this.select.offer
		};
		if (!this.agree.agreements.isObligation) {			
			this.broker.guardarPropuesta(data).subscribe((propuesta: any) => {
				if(propuesta.success == false) {					
					this.alertError = true;
					setTimeout(() => {
						this.router.navigate(['/deudas']);
					}, 3000);
				} else {		
					this.order()			
					this.alertCheck = true;
					window.scrollTo( 0, 0);
					const data1 = {
						de: this.debtService.debtSelect.de,
						type: this.agree.debtItem.type,
						project: environment.product,
						onlyQuery: true,
						withPass: false,
						byEmail: false,
						country: environment.country,
						idSession: this.sesion.sesionCookie
					};
					this.broker.descargarPDF(data1).subscribe((estado: any) => {	
						this.select.state = estado;
						this.select.de = data1.de;						
						this.select.nitCompany = this.select.offer.agreement.nit;
						this.broker.dataLinea = this.select.nitCompany;
						this.broker.nameCompanyAccept = this.select.state.agreement.nameBank;
						setTimeout(() => {
							this.router.navigate(['/imprimir']);
							this.loading.close();
						}, 3000);
						this.loading.close();
					}, error => {

					}); 
					$('#loading').modal('hide');
				}				
				//this.agree.companyParamsDirect(data)
				//this.router.navigate(['/deudas']);
				//this.router.navigate(['/imprimir']);
			}, error => {
				$('#loading').modal('hide');
				this.router.navigate(['/deudas']);

			});
		} else {
			const obligation = {
				sessionID: this.sesion.sesionCookie,
				obligation: this.select.obligation,
				offer: this.select.offer
			};
			// this.broker.guardarPropuestaObligacion(obligation).subscribe((propuesta: any) => {
			//   //this.broker.account = this.agree.debtItem.numberAccount
			//   setTimeout(() => {
			//     $('#loading').modal('hide');
			//   }, 1000);
			//   //this.router.navigate(['/deudas']);
			//   this.router.navigate(['/imprimir']);
			// }, (error: any) => {
			//   $('#loading').modal('hide');
			//   this.router.navigate(['/deudas']);

			// });
    		}
  	}

	acceptCheck() {
		this.sign.acceptCheckService();
	}

	acceptCheck2() {
		this.sign.acceptCheckService2();
	}

	order() {
		const MD5 = require("crypto-js/md5");
		
		const cDate = new Date(); 
		const email = this.guard.user.correo;
		//const email = "michael.reyes@experian.com";
		const product_id = "ponte_al_dia";
		const total_price = 1;  
		const orderId = MD5(email).toString(); //Sugiero usar una identificación basada en el email 
		const quantity = 1 ; //Siempre tendremos un solo producto por carrito 
		
		const Data = { 
			"id" : orderId, 
			"created_at" : cDate, 
			"customer" : { 
				"accepts_marketing" : true, 
				"created_at" : cDate , 
				"updated_at" : cDate, 
				"email" : email, 
			}, 
			"line_items": [{ 
				"product_id": product_id, 
				"quantity": quantity, 
				"sku" : "", 
				"name" : "", 
				"url" : "", 
				"image": "", 
				"price": total_price, 
				"currency" : "COP" 
			}], 
			"status":{
				"name": "Created"
			}, 
			"subtotal_price" : total_price, 
			"total_shipping" : 0, 
			"total_tax" : 0, 
			"total_price" : total_price 

		}; 
		this.broker.orderService(Data).subscribe(result => {
			},
			error => {}
		);
	}  
}