import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { CanActiveViaAuthGuard } from './core/services/auth-guard.service';
import { ErrorComponent } from './shared/error/error.component';
import { ConfirmComponent } from './pages/confirm/confirm.component';
import { PrintComponent } from './pages/print/print.component';
import { FaqComponent } from './pages/faq/faq.component';
import { HomeGuard } from './core/services/home.guard';

const routes: Routes = [
  { path: '', component: HomeComponent , pathMatch: 'full', canActivate: [HomeGuard]},
  { path: 'deudas', loadChildren: ()=>import('./pages/debts/debts.module').then(m => m.DebtsModule), pathMatch: 'full', canActivate: [CanActiveViaAuthGuard]},
  { path: 'no-deudas', loadChildren: ()=>import('./pages/debts/debts.module').then(m => m.DebtsModule), pathMatch: 'full', canActivate: [CanActiveViaAuthGuard]},
  { path: 'propuesta', loadChildren: ()=>import('./pages/agree/agree.module').then(m => m.AgreeModule), pathMatch: 'full'},
  { path: 'confirmar', component: ConfirmComponent, pathMatch: 'full'},
  { path: 'imprimir', component: PrintComponent, pathMatch: 'full'},
  { path: 'faq', component: FaqComponent, pathMatch: 'full'},
  { path: 'error', component: ErrorComponent, pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }