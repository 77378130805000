import { Component, OnInit } from '@angular/core';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  
  year: any;
  terminosycondiciones = environment.enlaces_cloudFront.url + environment.enlaces_cloudFront.terminos_condiciones;
  politicasPrivacidad = environment.enlaces_cloudFront.url + environment.enlaces_cloudFront.politica_privacidad;

  constructor(public sesion: SesionService, private dataLayerService: DataLayerService ) {

   }

  ngOnInit() {
    const d = new Date();
    this.year = d.getFullYear();
  }

  /**
   * Texto del link que sera usado para marcar en el evento de G4
   * @param btn_text 
   */
  enviar_dataLayer( btn_text: string|any ){
    // GA4 - clic_footer_menu
    this.dataLayerService.dl_eventoGA4_menu( 'clic_footer_menu', btn_text );
  }

}
